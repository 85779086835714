//Transpose an array of translates about some pivot extracting languages
export const transposeAbout = (arr, languagesArray, pivot) => {
  const pivotedDict = {};
  //For each row, assign the pivot to the identified language
  arr.forEach((row) => {
    pivotedDict[getLanguageName(row.languageId, languagesArray)] = row[pivot];
  });
  return pivotedDict;
};

export const getLanguageId = (name, languagesArray) => {
  return languagesArray.filter((lang) => lang.name === name)[0]["id"];
};

export const getLanguageName = (id, languagesArray) => {
  if (id === null || languagesArray === null) {
    return null;
  }
  return languagesArray.filter((lang) => lang.id === id)[0]["name"];
};

export const getExerciseTypeName = (id, exerciseTypesArray) => {
  return exerciseTypesArray.filter((type) => type.id === id)[0]["type"];
};

export const getExerciseCategoryName = (id, exerciseCategoriesArray) => {
  return exerciseCategoriesArray.filter((category) => category.id === id)[0][
    "name"
  ];
};
