import {
  TopToolbar,
  ExportButton,
  ListContextProvider,
  useListController,
  useListContext,
} from "react-admin";
import Button from "@mui/material/Button";

import { useGetList } from "react-admin";
import { useEffect, useState } from "react";
import { prepareCoursePostMessage } from "../iframetools";

export const CourseShow = ({ children, ...props }: any) => {
  return (
    <ListContextProvider value={useListController(props)}>
      <InternalCourseShow {...props} />
    </ListContextProvider>
  );
};

const InternalCourseShow = ({ children, ...props }: any) => {
  const [languagesArray, setLanguageArray]: any = useState(null);
  const {
    data: languageData,
    isLoading,
    error,
  } = useGetList("languages", {
    pagination: { page: 1, perPage: 10 },
  });

  useEffect(() => {
    if (!error && !isLoading) {
      setLanguageArray(languageData);
    }
  }, [isLoading, error, languageData]);

  const [lang, setLang]: any = useState("en");

  const { data } = useListContext();

  const handleSubmit = () => {
    const iframe = document.getElementById(`main-iframe`) as HTMLIFrameElement;
    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(
        prepareCoursePostMessage(data, lang, languagesArray),
        "*"
      );
    }
  };

  return (
    <ListContextProvider value={useListController(props)}>
      <Button onClick={() => setLang(lang === "en" ? "ar" : "en")}>
        Toggle
      </Button>
      <div></div>
      <div>
        <div>
          <Button onClick={handleSubmit}>Submit</Button>

          {lang ? (
            <iframe
              title={"Preview page"}
              id="main-iframe"
              src={"https://inglisi-dev.light-it.co/" + lang + "/preview-page"}
              height={500}
              width="70%"
            />
          ) : (
            <h1>Loading</h1>
          )}
        </div>
      </div>
    </ListContextProvider>
  );
};
