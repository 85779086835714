export const INSTRUCTION = "instruction";
export const INTONATION = "intonation";
export const TITLE = "title";
export const FLASH_CARD = "flash_card";
export const FLIP_CARD = "flip_card";
export const READ_TEXT = "read_text";
export const READ_TEXT_WITH_XP = "read_text_with_xp";
export const MULTIPLE_CHOICE_VERTICAL = "multiple_choice_vertical";
export const MULTIPLE_CHOICE_HORIZONTAL = "multiple_choice_horizontal";
export const INTONATION_CHECK = "intonation_check";
export const DRAG_AND_DROP = "drag_and_drop";
export const SHORT_ANSWER = "short_answer";
export const DROPDOWN = "dropdown";
export const LISTEN_AND_SPEAK = "listen_and_speak";
export const MATCH_THE_WORDS = "match_the_words";
export const WHAT_DO_YOU_DO_ON_THE_WEEKEND = "what_do_you_do_on_the_weekend";
export const DROWNING_MAN = "drowning_man";

export const TYPE = "type";

export const DELETE = "delete";
export const UPDATE = "update";
export const CREATE = "create";

export const ENGLISH = "en";
export const FRENCH = "fr";
export const ARABIC = "ar";

export const complexityChoices = [
  { id: 1, name: "Harder" },
  { id: 0, name: "Average Level" },
  { id: -1, name: "Easier" },
];
