import { Book } from "@mui/icons-material";
import { FilterList, FilterListItem, useGetList } from "react-admin";
import { sentenceCase } from "sentence-case";

export const ExercisesFilters = () => {
  const { data: exerciseTypes } = useGetList("exerciseTypes");
  const { data: exerciseCategories } = useGetList("exerciseCategories");

  return (
    <>
      <FilterList label="Exercise Type" icon={<Book />}>
        {exerciseTypes?.map((exerciseType) => (
          <FilterListItem
            label={sentenceCase(exerciseType.type)}
            key={exerciseType.type}
            value={{ "exerciseTypeId||$eq||": exerciseType.id }}
          />
        ))}
      </FilterList>
      <FilterList label="Exercise Category" icon={<Book />}>
        {exerciseCategories?.map((exerciseCategory) => (
          <FilterListItem
            label={sentenceCase(exerciseCategory.name)}
            key={exerciseCategory.name}
            value={{ "exerciseCategoryId||$eq||": exerciseCategory.id }}
          />
        ))}
      </FilterList>
    </>
  );
};
