import EditorButton from "./Button";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";

export const AlignLeftButton = ({ onMouseDown, border }) => {
  return (
    <EditorButton onMouseDown={onMouseDown} border={border}>
      <FormatAlignLeftIcon />
    </EditorButton>
  );
};
