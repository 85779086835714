import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";

export const TitleExercise = () => {
  return (
    <>
      <ContentEditor source="content" label="Big text" />
      <ContentEditor source="description" label="Small text" />
    </>
  );
};
