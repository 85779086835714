import { TextInput, SelectInput, SimpleForm } from "react-admin";
import { Create } from "react-admin";
import { TranslatesDescription } from "../customInputs";
import { Languages } from "../utilities/enums";
import { CreateSelectArrayFromEnum } from "../utilities/utils";

export const CourseCreate = () => {
  //This is a peculiarity with courses storing enum rather than id
  const langDict = CreateSelectArrayFromEnum(Languages);

  return (
    <Create>
      <SimpleForm>
        <TextInput source="level" />
        <SelectInput source="language" choices={langDict} />
        <TranslatesDescription listOfInputs={["title", "description"]} />
      </SimpleForm>
    </Create>
  );
};
