import { Card, CardContent } from "@mui/material";
import { Title, useGetList, useGetMany, useShowController } from "react-admin";
import { prepareExercisePostMessage } from "../iframetools";

export const ExerciseShow = () => {
  const {
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    refetch, // callback to refetch the record via dataProvider.getOne()
    resource, // the resource name, deduced from the location. e.g. 'posts'
  } = useShowController();

  //Need to pull the list of exercise types and categories to pass it to the
  //prepareExercise function
  const { data: exerciseTypesArray, isLoading: exerciseTypesListLoading } =
    useGetList("exerciseTypes");
  const {
    data: exerciseCategoriesArray,
    isLoading: exerciseCategoriesListLoading,
  } = useGetList("exerciseCategories");
  const { data: languagesArray, isLoading: languagesArrayLoading } =
    useGetList("languages");

  const handleSubmit = (data, lang) => {
    const iframe = document.getElementById(`main-iframe`) as HTMLIFrameElement;
    const message = prepareExercisePostMessage(
      data,
      lang,
      exerciseTypesArray,
      exerciseCategoriesArray,
      languagesArray
    );
    console.log(message);
    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  if (
    !isLoading &&
    !exerciseCategoriesListLoading &&
    !exerciseTypesListLoading &&
    !languagesArrayLoading
  ) {
    console.log("calling log");
    setTimeout(() => {
      handleSubmit(record, "ar");
    }, 2000);
  }
  return (
    <Card variant="outlined">
      <Title title={`Showing exercise ${defaultTitle}`} />
      <CardContent>
        <div>
          <iframe
            title={"Preview page"}
            id="main-iframe"
            src={"https://inglisi-dev.light-it.co/" + "ar" + "/preview-page"}
            height={600}
            width="100%"
          />
        </div>
      </CardContent>
    </Card>
  );
};
