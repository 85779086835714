import React from "react";
import { useWatch } from "react-hook-form";
import { DraggableList } from "./LessonDraggableList";
import { OrderItemType } from "./LessonOrderItemType";

export const DraggablePane = ({
  order,
  setOrder,
  currentLessonData,
  setCurrentLessonData,
  lessonsData,
}) => {
  const curCourse = useWatch({ name: "targetCourse" });
  const curLanguage = useWatch({ name: "targetLanguage" });

  React.useEffect(() => {
    //List should only be rendered if curCourse and curLanguage are picked
    if (!curCourse || !curLanguage || !lessonsData) {
      return;
    }
    console.log("Filtering in the Draggable pane");
    let filteredMappedData = lessonsData
      .filter((curLesson) => {
        //Each lesson has multiple courseLessons; check if any match curCourse
        //and return true
        return (
          curLesson.courseLessons.filter((curCourseLesson) => {
            return curCourseLesson.courseId === curCourse;
          }).length > 0
        );
      })
      .map((curLesson) => {
        //Get the current courseLesson (guaranteed to exist since only items with valid
        //courseLessons have reached this stage)
        let curCourseLesson = curLesson.courseLessons.filter(
          (curCourseLesson) => {
            return curCourseLesson.courseId === curCourse;
          }
        )[0];
        //For the translates, there is no guarantee on the order of translates received
        //Moreover, some translates may not exist for some languages
        //Need to check if they exist; N.B. if they exist, they can still be blank (empty string)
        let curTranslate = curLesson.translates.filter((curTranslate) => {
          return curTranslate.languageId === curLanguage;
        });
        if (curTranslate.length > 0) {
          //Only one translate would match the languageId
          curTranslate = curTranslate[0];
        } else {
          curTranslate = null;
        }
        let retObject: OrderItemType = {
          id: curCourseLesson.id,
          title: curTranslate ? curTranslate.title : "No title",
          subtitle: curTranslate ? curTranslate.subtitle : "No subtitle",
          description: curTranslate
            ? curTranslate.description
            : "No description",
          curOrder: curCourseLesson.order,
        };
        return retObject;
      });
    //Empty course >> Nothing to render
    if (filteredMappedData.length === 0) {
      return;
    }
    filteredMappedData.sort(function (a, b) {
      return a.curOrder - b.curOrder;
    });
    setCurrentLessonData(filteredMappedData);
    setOrder(filteredMappedData.map((_, index) => index));
  }, [curCourse, curLanguage, lessonsData, setOrder, setCurrentLessonData]);
  return (
    <DraggableList
      items={currentLessonData}
      order={order}
      setOrder={setOrder}
    />
  );
};
