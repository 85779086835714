import { BOLD, ITALIC, UNDERLINE } from "../utilities/constants";

export const Leaf = (props, name) => {
  return (
    <span
      dir={props.leaf.dir}
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? BOLD : "normal",
        fontStyle: props.leaf.italic ? ITALIC : "normal",
        textDecoration: props.leaf.underline ? UNDERLINE : "none",
        color: props.leaf.color ? props.leaf.color : "#000",
        fontSize: props.leaf.fontSize ? props.leaf.fontSize : "20px",
      }}
    >
      {props.children}
    </span>
  );
};
