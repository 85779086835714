import {
  DRAG_AND_DROP,
  FLASH_CARD,
  INSTRUCTION,
  INTONATION,
  INTONATION_CHECK,
  MULTIPLE_CHOICE_VERTICAL,
  DROPDOWN,
  SHORT_ANSWER,
  READ_TEXT,
  READ_TEXT_WITH_XP,
  TITLE,
  LISTEN_AND_SPEAK,
  MATCH_THE_WORDS,
  WHAT_DO_YOU_DO_ON_THE_WEEKEND,
  DROWNING_MAN,
  FLIP_CARD,
  MULTIPLE_CHOICE_HORIZONTAL,
} from "../utilities/constants";
import { DragAndDropExercise } from "./subexercises/DragAndDropExercise";
import { DropdownExercise } from "./subexercises/DropdownExercise";
import { FlashCardExercise } from "./subexercises/FlashCardExercise";
import { FlipCardExercise } from "./subexercises/FlipCardExercise";
import { GameExercise } from "./subexercises/GameExercise";
import { InstructionExercise } from "./subexercises/InstructionExercise";
import { IntonationCheckExercise } from "./subexercises/IntonationCheck";
import { IntonationExercise } from "./subexercises/IntonationExercise";
import { ListenAndSpeakExercise } from "./subexercises/ListenAndSpeakExercise";
import { MultipleChoiceHorizontalExercise } from "./subexercises/MultipleChoiceHorizontal";
import { MultipleChoiceVerticalExercise } from "./subexercises/MultipleChoiceVertical";
import { ShortAnswerExercise } from "./subexercises/ShortAnswerExercise";
import { TitleExercise } from "./subexercises/TitleExercise";
import { ReadTextExercise } from "./subexercises/ReadTextExercise";

export const MainExerciseStructure = ({ exerciseType }) => {
  switch (exerciseType) {
    case INSTRUCTION:
      return <InstructionExercise />;
    case TITLE:
      return <TitleExercise />;
    case READ_TEXT:
    case READ_TEXT_WITH_XP:
      return <ReadTextExercise />;

    case FLASH_CARD:
      return <FlashCardExercise />;
    case FLIP_CARD:
      return <FlipCardExercise />;
    case INTONATION:
      return <IntonationExercise />;
    case INTONATION_CHECK:
      return <IntonationCheckExercise />;
    case MULTIPLE_CHOICE_VERTICAL:
      return <MultipleChoiceVerticalExercise />;
    case MULTIPLE_CHOICE_HORIZONTAL:
      return <MultipleChoiceHorizontalExercise />;
    case DRAG_AND_DROP:
      return <DragAndDropExercise />;
    case DROPDOWN:
      return <DropdownExercise />;
    case SHORT_ANSWER:
      return <ShortAnswerExercise />;
    case LISTEN_AND_SPEAK:
      return <ListenAndSpeakExercise />;
    case MATCH_THE_WORDS:
    case WHAT_DO_YOU_DO_ON_THE_WEEKEND:
    case DROWNING_MAN:
      return <GameExercise />;
    default:
      return <></>;
  }
};
