import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";

export const InstructionExercise = () => {
  return (
    <ContentEditor
      source="content"
      label="Type in the instructions for the user."
    />
  );
};
