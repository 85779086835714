import { useWatch } from "react-hook-form";
import {
  ArrayInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  useGetList,
} from "react-admin";
import { Resource } from "../utilities/enums";
import { ResourceNoteCreateDialog } from "../resourceNotes/ResourceNoteCreateDialog";
import { ResourceNoteFormTab } from "../resourceNotes/ResourceNoteFormTab";
import { InstructionsTab } from "./InstructionsTab";
import { IsAdditionalTab } from "./IsAdditionalTab";

//createNoteDialog is only passed by EditExercise component >>
//null in Create components
export const ExerciseFormSkeleton = ({
  MainExerciseStructure,
  createNoteDialogState,
  handleDialogClose,
}) => {
  const { data, isSuccess } = useGetList("exerciseTypes");

  const MainExerciseTab = (props) => {
    const exerciseTypeId = useWatch({ name: "exerciseTypeId" });

    if (exerciseTypeId && isSuccess) {
      const exerciseType = data.filter((it) => it.id === exerciseTypeId)[0];

      return (
        <TabbedForm.Tab label="Exercise Body" {...props}>
          <MainExerciseStructure exerciseType={exerciseType.type} />
        </TabbedForm.Tab>
      );
    } else {
      return null;
    }
  };
  const AdditionalInstructionTab = (props) => {
    const exerciseTypeId = useWatch({ name: "exerciseTypeId" });

    if (exerciseTypeId && isSuccess) {
      const exerciseType = data.filter((it) => it.id === exerciseTypeId)[0];

      return (
        <TabbedForm.Tab label="User Instructions" {...props}>
          <InstructionsTab exerciseType={exerciseType.type} />
        </TabbedForm.Tab>
      );
    } else {
      return null;
    }
  };

  return (
    <TabbedForm>
      <TabbedForm.Tab label="Basic Details">
        {createNoteDialogState !== null ? (
          <ResourceNoteCreateDialog
            createNoteDialogState={createNoteDialogState}
            handleDialogClose={handleDialogClose}
            resourceType={Resource.EXERCISE.toString()}
          />
        ) : null}
        <ArrayInput source="lessonExercises">
          <SimpleFormIterator>
            <ReferenceInput source="lessonId" reference="lessons">
              <SelectInput
                optionText={(record) => {
                  //Defaulting to seoDescription for now if the lesson is not assigned
                  if (record.courseLessons.length === 0) {
                    return `Unassigned lesson - ${record.seoDescription}`;
                  } else {
                    return record.courseLessons
                      .map((item) => `${item.course.level} - ${item.order}`)
                      .join(" | ");
                  }
                }}
                optionValue="id"
              />
            </ReferenceInput>
            <NumberInput source="order" />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceInput
          source="exerciseCategoryId"
          reference="exerciseCategories"
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="exerciseTypeId" reference="exerciseTypes">
          <SelectInput optionText="type" optionValue="id" />
        </ReferenceInput>
      </TabbedForm.Tab>
      <MainExerciseTab />
      <AdditionalInstructionTab />
      <IsAdditionalTab />
      {createNoteDialogState !== null ? (
        <ResourceNoteFormTab resourceType={Resource.EXERCISE} />
      ) : null}
    </TabbedForm>
  );
};
