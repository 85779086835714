import {
  Button,
  useGetIdentity,
  useRecordContext,
  useUpdate,
} from "react-admin";
import LinkIcon from "@mui/icons-material/Link";
import { NoteStatus } from "../../utilities/enums";

export const ResolveResourceNoteButton = () => {
  const record = useRecordContext();
  const [update] = useUpdate<any>();
  const { data: identity } = useGetIdentity();

  const resolveResourceNote = async () => {
    console.log("Record being resolved");
    await update("resourceNotes", {
      id: record.id,
      data: {
        ...record,
        //@ts-ignore
        status: NoteStatus.RESOLVED,
        userId: identity?.id,
      },
      previousData: { ...record },
    });
  };

  return (
    <Button
      resource="exercises"
      label="Resolve issue"
      onClick={resolveResourceNote}
    >
      <LinkIcon />
    </Button>
  );
};
