import { Editor, Text, Transforms } from "slate";
import {
  BOLD,
  DIR,
  ITALIC,
  LEFT,
  LTR,
  NON_ALPHABET,
  RIGHT,
  RTL,
  UNDERLINE,
} from "./constants";
import { CustomEditor } from "./CustomEditor";

export const basicArabicPattern = /[\u0600-\u06FF]/;
export const basicEnglishPattern = /[A-Za-z]/;
const expandedArabicPattern = /[\u0600-\u06FF([<"{“”}'>\]).]/g;
const expandedEnglishPattern = /[A-Za-z([<"{“”}'>\]).]/g;
const NO_STRONGLY_TYPED_CHAR = Number.MAX_SAFE_INTEGER;

/**
 *
 * @todo Need to introduce multiple test cases so future changes don't
 * @todo break the current functionality
 */
export const unifyContent = (editor, values) => {
  console.log("ON-SLATE-EDITOR-CHANGE");
  console.log(values);
  for (let i = 0; i < values.length; i++) {
    const row = values[i];
    let curInd = 0;
    while (curInd < row.children.length) {
      unifyLine(editor, row.children[curInd], [i, curInd], row.children[0]);
      curInd++;
    }
  }
};

const unifyLine = (editor, node, curPath, baseNode) => {
  //Not nodes that need uniformity
  // debugger;
  if (!Text.isText(node) || node.text.trim().length === 0) {
    //This is a node that is left after all its content has been deleted; reset it to normal
    if (node.hasOwnProperty(DIR)) {
      CustomEditor.setTextAlign(editor, LEFT);
      Transforms.unsetNodes(editor, [DIR, BOLD, ITALIC, UNDERLINE], {
        at: curPath,
      });
    }
    return;
  }
  let targDir = getBaseDir(node.text);
  if (targDir !== NON_ALPHABET) {
    if (!node.hasOwnProperty(DIR)) {
      setDir(editor, curPath, 0, node.text.length, targDir, true);
      unifyRest(editor, node, curPath, baseNode);
    } else {
      if (targDir !== node[DIR]) {
        setDir(editor, curPath, 0, node.text.length, targDir, true);
      }
      unifyRest(editor, node, curPath, baseNode);
    }
  }
};

const unifyRest = (editor, node, curPath, baseNode) => {
  const curDir = node[DIR];
  const baseDir = getBaseDir(baseNode.text);
  let nextTransitionIndex = NO_STRONGLY_TYPED_CHAR;
  switch (baseDir) {
    case LTR:
      switch (curDir) {
        case LTR:
          nextTransitionIndex = getIndex(node.text, basicArabicPattern);
          break;
        case RTL:
          nextTransitionIndex = getIndex(node.text, expandedEnglishPattern);
          break;
        default:
          break;
      }
      break;
    case RTL:
      switch (curDir) {
        case RTL:
          nextTransitionIndex = getIndex(node.text, basicEnglishPattern);
          break;
        case LTR:
          nextTransitionIndex = getIndex(node.text, expandedArabicPattern);
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  if (nextTransitionIndex !== NO_STRONGLY_TYPED_CHAR) {
    setDir(
      editor,
      curPath,
      nextTransitionIndex,
      node.text.length,
      curDir === LTR ? RTL : LTR,
      false
    );
  }
};

//baseSetting indicates whether this is setting the first base in this line or not
const setDir = (
  editor,
  curPath,
  startIndex,
  endIndex,
  targDir,
  baseSetting
) => {
  Transforms.select(editor, {
    anchor: { path: curPath, offset: startIndex },
    focus: { path: curPath, offset: endIndex },
  });
  const marks = Editor.marks(editor);
  const needUpdate =
    startIndex !== 0 || (marks ? marks["dir"] !== targDir : true);
  if (needUpdate) {
    Editor.addMark(editor, DIR, targDir);
    if (baseSetting) {
      //If a textAlign exists, don't overwrite it!
      if (!editor.children[curPath[0]].textAlign) {
        CustomEditor.setTextAlign(editor, targDir === LTR ? LEFT : RIGHT);
      }
    }
    Transforms.deselect(editor);
    //Need to update the curPath since there is a new node now
    let newPath = [curPath[0], curPath[1]];
    if (!baseSetting) {
      newPath[1] = newPath[1] + 1;
    }
    endIndex -= startIndex;
    // setTimeout(() => {
    // Transforms.select(editor, Editor.end(editor));
    Transforms.select(editor, { path: newPath, offset: endIndex });
    // }, 20);
  }
};

//Tries to get the baseDirection for the current Block being evaluated
//To do so, it tries to find the first strongly typed character
const getBaseDir = (text) => {
  const curEnglishIndex = getIndex(text, basicEnglishPattern);
  const curArabicIndex = getIndex(text, basicArabicPattern);

  //Both being equal to Max means the current string contains non-strongly typed
  //characters
  if (
    curEnglishIndex === NO_STRONGLY_TYPED_CHAR &&
    curArabicIndex === NO_STRONGLY_TYPED_CHAR
  )
    return NON_ALPHABET;
  else {
    return curEnglishIndex < curArabicIndex ? LTR : RTL;
  }
};

const getIndex = (text, pattern) => {
  let match = pattern.exec(text);
  if (text.includes("few")) {
  }
  if (match) {
    return match.index;
  } else {
    return NO_STRONGLY_TYPED_CHAR;
  }
};
