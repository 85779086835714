import {
  BOLD,
  CENTER,
  ITALIC,
  LEFT,
  RIGHT,
  UNDERLINE,
} from "../utilities/constants";
import { CustomEditor } from "../utilities/CustomEditor";
import { Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import ToolbarColorPicker from "./ToolbarColorPicker";
import {
  BoldButton,
  UnderlineButton,
  ItalicButton,
  ExampleButton,
  SpeakerButton,
  ListenButton,
  AlignLeftButton,
  AlignCenterButton,
  AlignRightButton,
} from "./buttons";
import { EmojiInput } from "./EmojiPicker";
import { FixSpacesButton } from "./buttons/FixSpacesButton";
import { fixOverallSpaces } from "../utilities/spacing";
export const MiniLessonToolbar = ({
  editor,
  setExampleDialogState,
  setSpeakerDialogState,
  setListenDialogState,
}) => {
  const [color, setColorState] = useState("#000");

  useEffect(() => {
    setColorState(CustomEditor.getCurrentColor(editor));
  }, [editor, editor.selection]);

  return (
    <Toolbar>
      <BoldButton
        border={CustomEditor.isMarkActive(editor, BOLD)}
        onMouseDown={(event) => {
          event.preventDefault();
          CustomEditor.toggleMark(editor, BOLD);
        }}
      />
      <ItalicButton
        border={CustomEditor.isMarkActive(editor, ITALIC)}
        onMouseDown={(event) => {
          event.preventDefault();
          CustomEditor.toggleMark(editor, ITALIC);
        }}
      />
      <UnderlineButton
        border={CustomEditor.isMarkActive(editor, UNDERLINE)}
        onMouseDown={(event) => {
          event.preventDefault();
          CustomEditor.toggleMark(editor, UNDERLINE);
        }}
      />

      <AlignLeftButton
        border={true}
        onMouseDown={(event) => {
          event.preventDefault();
          CustomEditor.setTextAlign(editor, LEFT);
        }}
      />
      <AlignCenterButton
        border={true}
        onMouseDown={(event) => {
          event.preventDefault();
          CustomEditor.setTextAlign(editor, CENTER);
        }}
      />
      <AlignRightButton
        border={true}
        onMouseDown={(event) => {
          event.preventDefault();
          CustomEditor.setTextAlign(editor, RIGHT);
        }}
      />
      <EmojiInput
        handleChange={(e, emoji) => {
          CustomEditor.insertEmoji(editor, emoji);
        }}
      />
      <ToolbarColorPicker
        color={color}
        handleChange={(color) => {
          CustomEditor.setColorMark(editor, color.hex);
          setColorState(color.hex);
        }}
      />
      <ExampleButton
        onMouseDown={(event) => {
          event.preventDefault();
          setExampleDialogState(true);
        }}
      />
      <SpeakerButton
        onMouseDown={(event) => {
          event.preventDefault();
          setSpeakerDialogState(true);
        }}
      />
      <ListenButton
        onMouseDown={(event) => {
          event.preventDefault();
          setListenDialogState(true);
        }}
      />
      <FixSpacesButton
        onMouseDown={(event) => {
          event.preventDefault();
          fixOverallSpaces(editor);
        }}
      />
    </Toolbar>
  );
};
