//Takes in the data array and returns it in a format to be passed to the iFrame
//Note that the current function does not accept languages other than English
//And arabic

import { ARABIC, ENGLISH } from "../utilities/constants";
import {
  getExerciseCategoryName,
  getExerciseTypeName,
  getLanguageName,
} from "./common";

/**
 * @todo Add enums and strings to avoid string errors for stuff like type and title
 * @param lang is the current language that is being displayed
 */
export default function prepareExercisePostMessage(
  data,
  lang,
  exerciseTypesArray,
  exerciseCategoriesArray,
  languagesArray
) {
  //

  //Define the post Message dictionary that would be serialized later on
  let postMessage = {};
  postMessage["type"] = "IFRAME_PREVIEW/EXERCISE";

  //Since the payload is also deeply nested, it is defined individually
  let postMessagePayload = {};
  postMessagePayload.data = {};
  postMessagePayload.language = "ar";
  postMessagePayload.profile = { points: 80 };

  //The passed data is very similar to what needs to be passed (minus some tweaking)
  console.log("Passed data");
  console.log(data);
  let exerciseData = { ...data };
  exerciseData.instruction = {
    en: getInstruction(data, languagesArray, ENGLISH),
    ar: getInstruction(data, languagesArray, ARABIC),
  };
  exerciseData.type = getExerciseTypeName(
    exerciseData.exerciseTypeId,
    exerciseTypesArray
  );
  exerciseData.category = getExerciseCategoryName(
    exerciseData.exerciseCategoryId,
    exerciseCategoriesArray
  );
  exerciseData.answerOptions = exerciseData.groupOptions
    .map((curGroupOption) => {
      return curGroupOption["answerOptions"];
    })
    .flat();
  exerciseData.maxTryCount = 5;
  console.log(exerciseData);
  const lessonData = { language: "ar", timeDurationSeconds: 10 };
  postMessagePayload.data = { lessonData, exerciseData };
  postMessage.payload = postMessagePayload;

  //Returned a stringified version of the postMessage
  return JSON.stringify(postMessage);
}

const getInstruction = (data, langArray, lang) => {
  for (const translate of data.translates) {
    if (lang === getLanguageName(translate.languageId, langArray)) {
      return translate.shortDescription;
    }
  }
  return "Instruction does not exist for that language.";
};
