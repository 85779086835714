import { DELETE, UPDATE, CREATE } from "../../constants";
import { deleteFile, uploadFile } from "./fileRequests";

export const preprocessParams = async (resource, params, operationType) => {
  debugger;
  if (resource !== "exercises") {
    return;
  }
  //If an operation is delete >> any previous images and audios need to be deleted
  if (operationType === DELETE) {
    if (
      typeof params.previousData.image === "string" &&
      params.previousData.image.includes("s3.amazonaws.com")
    ) {
      await deleteFile(params.previousData.image);
    }
    if (
      typeof params.previousData.video === "string" &&
      params.previousData.video.includes("s3.amazonaws.com")
    ) {
      await deleteFile(params.previousData.video);
    }
    if (
      typeof params.previousData.audio === "string" &&
      params.previousData.audio.includes("s3.amazonaws.com")
    ) {
      await deleteFile(params.previousData.audio);
    }
  }
  //Very verbose but handles all edge cases (deleting or updating when a file exists or doesnt)
  else if (operationType === UPDATE) {
    if (params.data.image && params.data.image.rawFile) {
      if (
        typeof params.previousData.image === "string" &&
        params.previousData.image.includes("s3.amazonaws.com")
      ) {
        await deleteFile(params.previousData.image);
      }
      const newImage = await uploadFile(params.data.image);
      params.data.image = newImage.fileUrl;
    } else if (
      typeof params.previousData.image === "string" &&
      params.previousData.image.includes("s3.amazonaws.com") &&
      params.data.image === null
    ) {
      await deleteFile(params.previousData.image);
    }
    if (params.data.video && params.data.video.rawFile) {
      if (
        typeof params.previousData.video === "string" &&
        params.previousData.video.includes("s3.amazonaws.com")
      ) {
        await deleteFile(params.previousData.video);
      }
      const newVideo = await uploadFile(params.data.video);
      params.data.video = newVideo.fileUrl;
    } else if (
      typeof params.previousData.video === "string" &&
      params.previousData.video.includes("s3.amazonaws.com") &&
      params.data.video === null
    ) {
      await deleteFile(params.previousData.video);
    }
    if (params.data.audio && params.data.audio.rawFile) {
      if (
        typeof params.previousData.audio === "string" &&
        params.previousData.audio.includes("s3.amazonaws.com")
      ) {
        await deleteFile(params.previousData.audio);
      }
      const newAudio = await uploadFile(params.data.audio);
      params.data.audio = newAudio.fileUrl;
    } else if (
      typeof params.previousData.audio === "string" &&
      params.previousData.audio.includes("s3.amazonaws.com") &&
      params.data.audio === null
    ) {
      await deleteFile(params.previousData.audio);
    }
  } else if (operationType === CREATE) {
    if (params.data.image && params.data.image.rawFile) {
      const newImage = await uploadFile(params.data.image);
      params.data.image = newImage.fileUrl;
    }
    if (params.data.video && params.data.video.rawFile) {
      const newVideo = await uploadFile(params.data.video);
      params.data.video = newVideo.fileUrl;
    }
    if (params.data.audio && params.data.audio.rawFile) {
      const newAudio = await uploadFile(params.data.audio);
      params.data.audio = newAudio.fileUrl;
    }
  }
};
