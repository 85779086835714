import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { IconButton, List, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomEditor } from "../../utilities/CustomEditor";
import { useEffect } from "react";

export const ExamplesDialogForm = ({
  editor,
  exampleDialogState,
  setExampleDialogState,
  curExampleValues,
  setCurExampleValues,
}) => {
  //Defining the handleClose function for the dialog
  //It should clear any example values and reset the form
  const handleClose = () => {
    setCurExampleValues(null);
    setExampleDialogState(false);
    reset();
  };

  //Define default values for an empty example value
  let defaultValues = {
    word: "",
    examples: [{ text: "" }, { text: "" }, { text: "" }],
  };

  //Generate the form
  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultValues,
  });

  //Defining the field array since the examples is an array of values
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "examples",
  });

  //if values are given, need to update the form with those values
  useEffect(() => {
    if (curExampleValues) {
      setValue("word", curExampleValues.word);
      replace(curExampleValues.examples);
    }
  }, [curExampleValues, setValue, replace]);

  //Handling form submission (whether insertion or editing)
  const submitDialog = () => {
    //Get the examples from the form
    const examplesArray = getValues("examples")
      .filter((item) => item.text.length !== 0)
      //Need to escape all new line fields so they can be inserted into database
      .map((item) => item.text.replace("\n", "\\n"));

    //Reformatting the array to be of appropriate form
    //a dictionary of text-value pairs
    const dictExamples = examplesArray.map((x) => {
      return { text: x };
    });
    //Need to update the currentword as opposed to inserting
    if (curExampleValues) {
      CustomEditor.updateExample(editor, getValues("word"), dictExamples);
      setCurExampleValues(null);
    } else {
      //Need to insert an example word into the example
      CustomEditor.insertExample(editor, getValues("word"), dictExamples);
    }
    //Need to set the current values to null so they don't clash with
    //other example popup forms
    handleClose();
    reset();
  };
  return (
    <Dialog open={exampleDialogState} onClose={handleClose}>
      <DialogTitle>Example Pop-up form</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the different components of this example component.
          Please note that each example can be more than one line. Also note
          that the first line contains the word that would be displayed
        </DialogContentText>
        <form>
          <Controller
            name="word"
            control={control}
            render={({ field }) => (
              <TextField {...field} placeholder="Type the main word" />
            )}
          />

          <List>
            {fields.map((item, index) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Controller
                  name={`examples.${index}.text`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        multiline
                        placeholder="Type the example"
                        style={{ width: "80%" }}
                      />
                      <IconButton size="large" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                />
              </div>
            ))}
          </List>
          <IconButton size="large" onClick={() => append({ text: "" })}>
            <AddIcon />
          </IconButton>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" onClick={submitDialog}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
