import { FileInput, FormDataConsumer } from "react-admin";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export const AudioInput = ({ source, label }, ...rest) => {
  return (
    <FileInput source={source} label={label}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          //The reasonning is that during create or edit, there is an undefined; however,
          //during pre-submission processing, all of that gets replaced with an aws link
          console.log("Audio Player");
          console.log(formData);
          return formData.audio ? (
            <AudioPlayer
              src={formData.audio.src ? formData.audio.src : formData.audio}
              // other props here
            />
          ) : null;
        }}
      </FormDataConsumer>
    </FileInput>
  );
};
