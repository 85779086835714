import {
  BOLD,
  CENTER,
  ITALIC,
  LEFT,
  RIGHT,
  UNDERLINE,
} from "../utilities/constants";
import { CustomEditor } from "../utilities/CustomEditor";
import { Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import ToolbarColorPicker from "./ToolbarColorPicker";
import {
  BlankButton,
  BoldButton,
  ExampleButton,
  UnderlineButton,
  ItalicButton,
  SpeakerButton,
  MicButton,
  DropdownButton,
  PopupButton,
  ListenButton,
  AlignLeftButton,
  AlignRightButton,
  AlignCenterButton,
} from "./buttons";
import { EmojiInput } from "./EmojiPicker";
import { FontSizePicker } from "./FontSizePicker";
import { FixSpacesButton } from "./buttons/FixSpacesButton";
import { fixOverallSpaces } from "../utilities/spacing";
export const LessonsToolbar = ({
  editor,
  setExampleDialogState,
  setPopupDialogState,
  setSpeakerDialogState,
  setListenDialogState,
}) => {
  const [color, setColorState] = useState("#000");
  const [fontSize, setFontSize] = useState("20px");

  useEffect(() => {
    setColorState(CustomEditor.getCurrentColor(editor));
    setFontSize(CustomEditor.getCurrentFontSize(editor));
  }, [editor, editor.selection]);

  return (
    <>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <BoldButton
          border={CustomEditor.isMarkActive(editor, BOLD)}
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.toggleMark(editor, BOLD);
          }}
        />
        <ItalicButton
          border={CustomEditor.isMarkActive(editor, ITALIC)}
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.toggleMark(editor, ITALIC);
          }}
        />
        <UnderlineButton
          border={CustomEditor.isMarkActive(editor, UNDERLINE)}
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.toggleMark(editor, UNDERLINE);
          }}
        />
        <AlignLeftButton
          border={true}
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.setTextAlign(editor, LEFT);
          }}
        />
        <AlignCenterButton
          border={true}
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.setTextAlign(editor, CENTER);
          }}
        />
        <AlignRightButton
          border={true}
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.setTextAlign(editor, RIGHT);
          }}
        />
        <EmojiInput
          handleChange={(e, emoji) => {
            e.preventDefault();
            CustomEditor.insertEmoji(editor, emoji);
          }}
        />

        <ToolbarColorPicker
          color={color}
          handleChange={(color) => {
            CustomEditor.setColorMark(editor, color.hex);
            setColorState(color.hex);
          }}
        />
        <FontSizePicker
          handleChange={(newFontSize) => {
            setFontSize(newFontSize);
            CustomEditor.setFontSizeMark(editor, newFontSize);
          }}
          size={fontSize}
        />
      </Toolbar>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <BlankButton
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.insertBlank(editor);
          }}
        />
        <DropdownButton
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.insertDropdown(editor);
          }}
        />
        <MicButton
          onMouseDown={(event) => {
            event.preventDefault();
            CustomEditor.insertMicrophone(editor);
          }}
        />
        <ExampleButton
          onMouseDown={(event) => {
            event.preventDefault();
            setExampleDialogState(true);
          }}
        />
        <SpeakerButton
          onMouseDown={(event) => {
            event.preventDefault();
            setSpeakerDialogState(true);
          }}
        />
        <ListenButton
          onMouseDown={(event) => {
            event.preventDefault();
            setListenDialogState(true);
          }}
        />
        <PopupButton
          onMouseDown={(event) => {
            event.preventDefault();
            setPopupDialogState(true);
          }}
        />
        <FixSpacesButton
          onMouseDown={(event) => {
            event.preventDefault();
            fixOverallSpaces(editor);
          }}
        />
      </Toolbar>
    </>
  );
};
