import { Resource } from "../enums";
export const getResourceNotes = async (
  resourceId: string,
  resourceType: Resource,
  deep: boolean,
  restQueries: any
) => {
  const queryString = getQueryString(restQueries);
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  const resp = await fetch(
    `${process.env.REACT_APP_BACKEND}/resourceNotes/related?resourceId=${resourceId}&resourceType=${resourceType}&deep=${deep}${queryString}`,
    {
      method: "GET",
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
  );
  const resourceNotesList = await resp.json();
  return resourceNotesList;
};

const getQueryString = (params: any) => {
  let queryString = "";
  const { filter, pagination, sort } = params;
  if (sort) {
    queryString = queryString + `&sort[0]=${sort.field},${sort.order}`;
  }
  if (filter) {
    let curFilterIndex = 0;
    for (let curFilter in filter) {
      queryString =
        queryString +
        `&filter[${curFilterIndex}]=${curFilter}${filter[curFilter]}`;
      curFilterIndex++;
    }
  }
  if (pagination) {
    queryString =
      queryString + `&limit=${pagination.perPage}&page=${pagination.page}`;
  }
  return queryString;
};
