import { List, Datagrid, TextField, DateField, NumberField } from "react-admin";

export const PanelOrganizationList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <NumberField source="maxUsers" />
      <NumberField source="currentUsers" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
