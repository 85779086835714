import {
  Edit,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  TabbedForm,
} from "react-admin";
import { ContentEditor } from "../customInputs/contentEditor/ContentEditor";
import { lessonTransformer } from "./lessonFormTools";
import { ExercisesDataGrid } from "./components/ExercisesDataGrid";
import { CourseLessonsInput } from "./components/CourseLessonsInput";
import { ConditionalVideoInput } from "./components/ConditionalVideoInput";
import { ConditionalLessonLimitInput } from "./components/ConditionalLessonLimitInput";
import { LessonDurationNumberInput } from "./components/LessonDurationNumberInput";
import { useState } from "react";
import { LessonEditActions } from "./LessonEditActions";
import { ResourceNoteCreateDialog } from "../resourceNotes/ResourceNoteCreateDialog";
import { Resource } from "../utilities/enums";
import { ResourceNoteFormTab } from "../resourceNotes/ResourceNoteFormTab";
import { TranslatesDescription } from "../customInputs";

export const LessonEdit = () => {
  const [createNoteDialogState, setCreateNoteDialogState] =
    useState<boolean>(false);

  const handleDialogClose = () => {
    setCreateNoteDialogState(false);
  };

  return (
    <Edit
      transform={lessonTransformer}
      actions={
        <LessonEditActions
          setCreateNoteDialogState={setCreateNoteDialogState}
        />
      }
    >
      <TabbedForm
        warnWhenUnsavedChanges
        mutationMode="pessimistic"
        sanitizeEmptyValues
      >
        <TabbedForm.Tab label="Lesson Type">
          <ResourceNoteCreateDialog
            createNoteDialogState={createNoteDialogState}
            handleDialogClose={handleDialogClose}
            resourceType={Resource.LESSON.toString()}
          />
          <ReferenceInput source="lessonTypeId" reference="lessonTypes">
            <SelectInput optionText="type" optionValue="id" />
          </ReferenceInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Main Details">
          <ContentEditor
            source="seoDescription"
            label="Type the SEO Description for this lesson"
          />
          <LessonDurationNumberInput />
          <BooleanInput
            source="isPublic"
            label="Public Access"
            helperText="Should users be able to access this lesson without signing up?"
          />
          <CourseLessonsInput />
          <ConditionalVideoInput />
          <ConditionalLessonLimitInput />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Exercises">
          <ExercisesDataGrid />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Lesson Description">
          <TranslatesDescription
            listOfInputs={["title", "subtitle", "description"]}
          />
        </TabbedForm.Tab>
        <ResourceNoteFormTab resourceType={Resource.LESSON} />
      </TabbedForm>
    </Edit>
  );
};
