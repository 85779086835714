import { AudioInput } from "../../customInputs/AudioInput";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { intonationDefaultValue } from "../defaultValues";

export const IntonationCheckExercise = () => {
  return (
    <>
      <ContentEditor
        source="content"
        label="Exercise Content"
        //@ts-ignore
        defaultValue={intonationDefaultValue}
      />
      <AudioInput source="audio" label="Upload the Arianna audio." />
    </>
  );
};
