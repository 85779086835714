import { Edit, TextInput, SelectInput, TabbedForm, FormTab } from "react-admin";
import { Languages } from "../utilities/enums/languages.enum";
import { CreateSelectArrayFromEnum } from "../utilities/utils";
import { CourseEditActions } from "./CourseEditActions";
import { useState } from "react";
import { ResourceNoteCreateDialog } from "../resourceNotes/ResourceNoteCreateDialog";
import { Resource } from "../utilities/enums";
import { ResourceNoteFormTab } from "../resourceNotes/ResourceNoteFormTab";
import { TranslatesDescription } from "../customInputs";

export const CourseEdit = (props: any) => {
  const langDict = CreateSelectArrayFromEnum(Languages);

  const courseFormTransformer = (data: any, { previousData }: any) => {
    return {
      ...data,
      previousData: previousData,
    };
  };

  const [createNoteDialogState, setCreateNoteDialogState] =
    useState<boolean>(false);

  const handleDialogClose = () => {
    setCreateNoteDialogState(false);
  };

  return (
    <Edit
      transform={courseFormTransformer}
      actions={
        <CourseEditActions
          setCreateNoteDialogState={setCreateNoteDialogState}
        />
      }
    >
      <TabbedForm>
        <FormTab label="Course Details">
          <ResourceNoteCreateDialog
            createNoteDialogState={createNoteDialogState}
            handleDialogClose={handleDialogClose}
            resourceType={Resource.COURSE.toString()}
          />
          <TextInput source="level" />
          <SelectInput source="language" choices={langDict} />
          <TranslatesDescription listOfInputs={["title", "description"]} />
        </FormTab>
        <ResourceNoteFormTab resourceType={Resource.COURSE} />
      </TabbedForm>
    </Edit>
  );
};
