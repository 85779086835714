import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ReferenceField,
} from "react-admin";

export const PanelUserList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="username" />
      <TextField source="lastLogin" emptyText="N.A." label={"Last logged on"} />
      <EmailField source="email" label={"E-mail"} />
      <TextField source="roles" label="Role" />
      <ReferenceField source="organizationId" reference="panelOrganizations">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" label={"Created on"} />
      <DateField source="updatedAt" label={"Last updated on"} />
    </Datagrid>
  </List>
);
