import { Button, useArrayInput } from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";

export const AddItemWithDefaultIdButton = () => {
  const { append } = useArrayInput();
  return (
    <Button label="ra.action.add" onClick={() => append({ id: uuidv4() })}>
      <AddIcon />
    </Button>
  );
};
