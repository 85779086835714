import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Edit, SimpleForm, useNotify, useRefresh } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import { MiniContentEditor } from "../../MiniContentEditor";
import { serialize } from "../../utilities/serialization";

export const EditPopupDialog = ({
  editDialogState,
  setEditDialogState,
  editDialogId,
  setEditDialogId,
}) => {
  const handleDialogClose = () => {
    setEditDialogState(false);
  };

  const transform = (data) => ({
    ...data,
    en: serialize(data.en),
    ar: serialize(data.ar),
    fr: serialize(data.fr),
  });

  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify(`The popup has been updated`);
    refresh();
    setEditDialogState(false);
  };

  return (
    <Dialog
      anchor="right"
      id="create-drawer"
      open={editDialogState}
      onClose={handleDialogClose}
      fullScreen
    >
      <DialogTitle disableTypography className={"create-dialog-title"}>
        <h2>Popup create form...</h2>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Edit
          id={editDialogId}
          resource="popups"
          redirect={false}
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess }}
          transform={transform}
        >
          <SimpleForm>
            <MiniContentEditor source="ar" label="Arabic popup text" />
            <MiniContentEditor source="en" label="English popup text" />
            <MiniContentEditor source="fr" label="French popup text" />
          </SimpleForm>
        </Edit>
      </DialogContent>
    </Dialog>
  );
};
