//Copy pasted from the Speaker Dialog
//Probably can refactor both dialogs into one but not now
//A future endeavour possibly as I don't want to spend too much time optimizing
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { CustomEditor } from "../../utilities/CustomEditor";
import { useEffect } from "react";

export const ListenDialogForm = ({
  editor,
  listenDialogState,
  setListenDialogState,
  curListenValues,
  setCurListenValues,
}) => {
  //Defining the handleClose function for the dialog
  //It should clear any example values and reset the form
  const handleClose = () => {
    setCurListenValues(null);
    setListenDialogState(false);
    reset();
  };

  //Define default values for an empty example value
  let defaultValues = {
    listenText: "",
  };

  //Generate the form
  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultValues,
  });

  //if values are given, need to update the form with those values
  useEffect(() => {
    if (curListenValues) {
      setValue("listenText", curListenValues.listenText);
    }
  }, [curListenValues, setValue]);

  //Handling form submission (whether insertion or editing)
  const submitDialog = () => {
    //Need to update the currentword as opposed to inserting
    if (curListenValues) {
      CustomEditor.updateListen(editor, getValues("listenText"));
    } else {
      CustomEditor.insertListen(editor, getValues("listenText"));
    }
    handleClose();
    reset();
  };
  return (
    <Dialog open={listenDialogState} onClose={handleClose}>
      <DialogTitle>Listen Dialog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the statement which you would like to be read. As opposed
          to a speaker, this statement will be typed out to the user but it can
          also be read.
        </DialogContentText>
        <form>
          <Controller
            name="listenText"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Type the statement"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" onClick={submitDialog}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
