import { SimpleForm, TextInput, Create, NumberInput } from "react-admin";

export const PanelOrganizationCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <NumberInput source="maxUsers" />
      </SimpleForm>
    </Create>
  );
};
