import isHotkey from "is-hotkey";

export const blankHotKey = isHotkey("ctrl+k");
export const exampleHotKey = isHotkey("ctrl+e");
export const microphoneHotKey = isHotkey("ctrl+m");
export const boldHotKey = isHotkey("ctrl+b");
export const italicHotKey = isHotkey("ctrl+i");
export const underlineHotKey = isHotkey("ctrl+u");
export const dropdownHotKey = isHotkey("ctrl+d");
export const popupHotKey = isHotkey("ctrl+p");
export const speakerHotKey = isHotkey("ctrl+s");
export const listenHotKey = isHotkey("ctrl+l");
