import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  required,
  SaveContextProvider,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useRecordContext,
  useRefresh,
  useGetIdentity,
  ReferenceInput,
} from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import { CreateSelectArrayFromEnum } from "../utilities/utils";
import { NotePriority, NoteStatus } from "../utilities/enums";

export const ResourceNoteCreateDialog = ({
  createNoteDialogState,
  handleDialogClose,
  resourceType,
}) => {
  //Defining the create function
  const [create] = useCreate();
  const refresh = useRefresh();
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  const priorityDict = CreateSelectArrayFromEnum(NotePriority);
  const statusDict = CreateSelectArrayFromEnum(NoteStatus);

  // Defining the values for the savecontext
  const save = async (data) => {
    const resourceNoteData = {
      ...data,
      resource: resourceType,
      resourceId: record.id,
      createdByPanelUserId: identity ? identity.id : "ERROR",
    };
    await create(
      "resourceNotes",
      { data: resourceNoteData },
      {
        onSuccess: (data) => {
          refresh();
          handleDialogClose();
        },
      }
    );
  };
  const saving = false;
  const mutationMode = "pessimistic";

  return (
    <Dialog
      id="resource-note-create-dialog"
      open={createNoteDialogState}
      onClose={handleDialogClose}
    >
      <DialogTitle className={"create-dialog-title"}>
        <h2>Create a new note:</h2>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "400px" }}>
        <SaveContextProvider value={{ save, saving, mutationMode }}>
          <SimpleForm record={{}}>
            <TextInput
              source="text"
              multiline
              fullWidth
              label="What is the issue?"
              validate={[required("Must have a an issue to create an issue!")]}
            />
            <SelectInput
              source="priority"
              choices={priorityDict}
              helperText="Default is Normal."
              fullWidth
            />
            <SelectInput
              source="status"
              choices={statusDict}
              helperText="Default is Open."
              fullWidth
            />
            <ReferenceInput
              label="Assigned to"
              reference="panelUsers"
              source="assignedToPanelUserId"
            >
              <SelectInput fullWidth optionText={"username"} optionValue="id" />
            </ReferenceInput>
          </SimpleForm>
        </SaveContextProvider>
      </DialogContent>
    </Dialog>
  );
};
