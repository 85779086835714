import { Labeled } from "react-admin";
import { SketchPicker } from "react-color";
import { presetColors } from "./contentEditor/utilities/constants";

export interface Color {
  hex: string;
}

export const ColorInput = ({ label, helperText, value, onChange }) => {
  return (
    <>
      <p> {helperText} </p>
      <Labeled label="Color">
        <SketchPicker
          color={value}
          onChange={onChange}
          label={label}
          presetColors={presetColors}
        />
      </Labeled>
    </>
  );
};
