import {
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { AudioInput } from "../../customInputs/AudioInput";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { EnhancedImageInput } from "../../customInputs/ImageInput";
import { v4 as uuidv4 } from "uuid";
import { AddItemWithDefaultIdButton } from "./AddItemWithDefaultIdButton";
import { EnhancedVideoInput } from "../../customInputs/VideoInput";

export const MultipleChoiceVerticalExercise = () => {
  return (
    <>
      <ContentEditor
        source="content"
        label="The text to be shown to the user"
      />
      <TextInput fullWidth source="description" label="Text below image" />
      <TextInput
        fullWidth
        source="helpHint"
        label="This is the hint that will show to the user."
      />
      <NumberInput
        source="maxTryCount"
        label="What is the maximum try count?"
        defaultValue={2}
      />
      <AudioInput
        source="audio"
        label="Upload an audio file for this exercise."
      />
      <EnhancedImageInput
        source="image"
        label="Upload an optional image for this exercise."
      />
      <EnhancedVideoInput
        source="video"
        label="Upload a video for this exericse. Do not upload video if you uploaded a picture."
      />
      <ArrayInput
        source="groupOptions"
        defaultValue={[
          {
            id: uuidv4(),
            answerOptions: Array(4)
              .fill(0)
              .map((item) => {
                return {
                  id: uuidv4(),
                };
              }),
            rightAnswers: [{}],
          },
        ]}
      >
        <SimpleFormIterator
          disableReordering
          disableAdd
          disableRemove
          disableClear
        >
          <ArrayInput source="answerOptions">
            <SimpleFormIterator addButton={<AddItemWithDefaultIdButton />}>
              <TextInput source="answer" />
              <NumberInput source="count" defaultValue={1} />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              return (
                <ArrayInput
                  source={getSource ? getSource("rightAnswers") : "null"}
                >
                  <SimpleFormIterator
                    disableAdd
                    disableRemove
                    disableClear
                    disableReordering
                  >
                    <SelectInput
                      validate={required("You have to choose an answer!")}
                      source={`answerOptionId`}
                      choices={scopedFormData.answerOptions}
                      optionText={"answer"}
                      optionValue={"id"}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
