import { RaRecord } from "react-admin";
import { serialize } from "../customInputs/contentEditor/utilities/serialization";

//Need to make sure to only call serialization when there is actual text to be
//serialized not just empty text
//Also there are two form transformers for when there are differences between create and edit
export const formTransformer = async (data: RaRecord) => {
  if (typeof data.image === "string" && data.image.trim() === "") {
    delete data.image;
  }
  if (data.image && data.image.hex) {
    data.image = data.image.hex;
  }
  //To make sure no complexity is changed if no additional
  if (
    !data.additionalForId ||
    data.additionalForId === null ||
    data.additionalForId === ""
  ) {
    data.complexity = 0;
  }
  if (data.description == null) {
    data.description = "";
  } else if (data.description !== "") {
    data.description = serialize(data.description);
  }
  if (data.content == null) {
    data.content = "";
  } else if (data.content !== "") {
    data.content = serialize(data.content);
  }
  if (data.question && data.question !== "") {
    data.question = serialize(data.question);
  }
  if (data.groupOptions) {
    data.groupOptions = data.groupOptions.map((curGroupOption, index) => {
      const curRightAnswers = curGroupOption["rightAnswers"].map(
        (curRightAnswer, index) => {
          return {
            ...curRightAnswer,
            order: index,
          };
        }
      );
      return {
        ...curGroupOption,
        order: index,
        rightAnswers: curRightAnswers,
      };
    });
  }
  return data;
};
