import EditorButton from "./Button";
import FormatBoldIcon from "@mui/icons-material/FormatBold";

export const BoldButton = ({ onMouseDown, border }) => {
  return (
    <EditorButton onMouseDown={onMouseDown} border={border}>
      <FormatBoldIcon />
    </EditorButton>
  );
};
