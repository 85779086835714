import { NumberInput, TextInput } from "react-admin";
import { AudioInput } from "../../customInputs/AudioInput";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { EnhancedImageInput } from "../../customInputs/ImageInput";
import { EnhancedVideoInput } from "../../customInputs/VideoInput";

export const ListenAndSpeakExercise = () => {
  return (
    <>
      <ContentEditor
        source="content"
        label="The text to that would be read out loud and that user has to speak back."
      />
      <TextInput
        source="description"
        fullWidth
        label="The text to be displayed below the image"
      />
      <NumberInput
        source="maxTryCount"
        label="What is the maximum try count?"
        defaultValue={2}
      />

      <AudioInput
        source="audio"
        label="Upload an audio file for this exercise. If no audio file is uploaded, the text would be read out by the system."
      />
      <EnhancedImageInput
        source="image"
        label="Optional; Upload an image for this exercise."
      />
      <EnhancedVideoInput
        source="video"
        label="Upload a video for this exercise. Do not upload both an image if uploading a video"
      />
    </>
  );
};
