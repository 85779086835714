import {
  BOLD,
  CENTER,
  COLOR,
  DIR,
  FONTSIZE,
  ITALIC,
  LEFT,
  LTR,
  RIGHT,
  RTL,
  UNDERLINE,
} from "./constants";
import { getDefaultValue } from "./defaultValue";

const deserializtionRegex =
  /<span (dir='(?:ltr|rtl)' )?(style=.*?)?>(.*?)<\/span>|\$\[(.+?)\]|[^$<>]+/g;

export const deserialize = (fullValue, defaultFontSize) => {
  //No need to serialize if fullValue is already an array
  if (Array.isArray(fullValue)) {
    return fullValue;
  }
  if (!fullValue.includes("<div")) {
    return getDefaultValue();
  }

  let deserializedArr = [];
  fullValue.split("<br>").forEach((line) => {
    //Need to remove the surrounding div tag before passing to regex
    const firstAngleBracketIndex = line.indexOf(">");
    const divText = line.substring(0, firstAngleBracketIndex);
    const dirTargeted = divText.includes("dir") ? LTR : RTL;
    let overallTextAlign;
    if (divText.includes(RIGHT)) {
      overallTextAlign = RIGHT;
    } else if (divText.includes(CENTER)) {
      overallTextAlign = CENTER;
    } else {
      overallTextAlign = LEFT;
    }
    line = line.substring(firstAngleBracketIndex + 1, line.length - 6);
    const curParagraphChildren = Array.from(
      line.matchAll(deserializtionRegex),
      (m) => {
        // Passing the different capture groups, deserialize element is congizant of
        // what an element being undefined means
        return deserializeElement(
          m[0],
          m[1],
          m[2],
          m[3],
          m[4],
          dirTargeted,
          defaultFontSize
        );
      }
    );
    let curParagraph = {};
    curParagraph["type"] = "paragraph";
    curParagraph["children"] =
      curParagraphChildren.length !== 0
        ? curParagraphChildren
        : [{ text: " " }];
    curParagraph["textAlign"] = overallTextAlign;
    deserializedArr.push(curParagraph);
  });
  return deserializedArr;
};

//Note that slate requires the deserialized void elements (such as blank, microphone, ...)
//to have blank elements in between; however, the normalization procedure can deal with it
const deserializeElement = (
  elem,
  spanDir,
  spanStyle,
  spanText,
  dslText,
  dirTargeted,
  defaultFontSize
) => {
  let ret = {};
  if (spanText) {
    if (spanDir) {
      ret[DIR] = dirTargeted;
    }
    if (spanStyle) {
      if (spanStyle.includes("fontWeight")) {
        ret[BOLD] = true;
      }
      if (spanStyle.includes("fontStyle")) {
        ret[ITALIC] = true;
      }
      if (spanStyle.includes("textDecoration")) {
        ret[UNDERLINE] = true;
      }
      if (spanStyle.includes("color")) {
        ret[COLOR] = spanStyle.split("color:")[1].substring(0, 7);
      }
      //This is a half-assed solution at trying to prevent every item being wrapped in span
      //because of fontSize
      if (spanStyle.includes("fontSize")) {
        let targFontSize = spanStyle.split("fontSize:")[1].substring(0, 4);
        if (targFontSize !== defaultFontSize) {
          ret[FONTSIZE] = targFontSize;
        }
      }
    }
    ret["text"] = spanText;
  } else if (dslText) {
    ret["children"] = [{ text: "" }];
    if (dslText.includes("blank")) {
      ret["type"] = "blank";
    } else if (dslText.includes("microphone")) {
      ret["type"] = "microphone";
    } else if (dslText.includes("dropdown")) {
      ret["type"] = "dropdown";
    } else if (dslText.includes("speaker")) {
      ret["type"] = "speaker";
      ret["speakerText"] = dslText.split("/")[1];
    } else if (dslText.includes("listen")) {
      ret["type"] = "listen";
      ret["listenText"] = dslText.split("/")[1];
    } else if (dslText.includes("dictionary")) {
      ret["type"] = "dictionary";
      ret["dictionaryText"] = dslText.split("/")[1];
    } else if (dslText.includes("examples_pop_up")) {
      ret["type"] = "example";
      ret["word"] = dslText.split("/")[1];
      ret["examples"] = dslText
        .split("/")[2]
        .split("|")
        .map((it) => {
          return { text: it };
        });
    } else if (dslText.includes("quotes_pop_up")) {
      ret["type"] = "popup";
      const popupValues = dslText.split("/");
      ret["popupid"] = popupValues[1];
      if (popupValues.length === 3) {
        ret["afterQuestion"] = true;
      }
    }
  } else {
    ret["text"] = elem;
  }
  return ret;
};
