import { Button, TopToolbar, ShowButton } from "react-admin";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { CreateExerciseButton } from "./components/CreateNextExerciseButton";
import { ExericseReorderButton } from "./components/ExericseReorderButton";

export const LessonEditActions = ({ setCreateNoteDialogState }) => (
  <TopToolbar>
    <ShowButton />
    <Button
      onClick={() => {
        setCreateNoteDialogState(true);
      }}
      label="Create note"
    >
      <NoteAddIcon />
    </Button>
    <CreateExerciseButton />
    <ExericseReorderButton />
  </TopToolbar>
);
