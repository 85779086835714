import { TextInput } from "react-admin";
import { getLanguageName } from "../iframetools/common";
import { ARABIC, FRENCH } from "../utilities/constants";

export const LanguageAwareTextInput = ({
  source,
  label,
  languagesList,
  langId,
  defaultValue = null,
}: {
  source: string;
  label: string;
  languagesList: any;
  langId: string;
  defaultValue?: null | string;
}) => {
  const curLanguage = getLanguageName(langId, languagesList);
  switch (curLanguage) {
    case null:
      return null;
    case ARABIC:
      return (
        <TextInput
          label={label}
          source={source}
          fullWidth
          defaultValue={defaultValue}
          multiline
          sx={{
            textAlignLast: "right",
          }}
        />
      );
    default:
      return (
        <TextInput
          label={label}
          source={source}
          defaultValue={defaultValue}
          fullWidth
          multiline
        />
      );
  }
};
