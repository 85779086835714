import { ColorInput } from "../../customInputs/ColorInput";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { useController } from "react-hook-form";
import { defaultFontSizes, FLASHCARD_PURPLE_COLOR } from "../defaultValues";
import { FLASH_CARD } from "../../utilities/constants";

export const FlashCardExercise = () => {
  //name refers to the form field that would be modified by this input
  const { field } = useController({
    name: "image",
    defaultValue: { hex: FLASHCARD_PURPLE_COLOR },
  });

  return (
    <>
      <ContentEditor
        source="content"
        label="Frontside"
        backgroundColor={field.value.hex ? field.value : { hex: field.value }}
        fontColor="#fff"
        defaultFontSize={defaultFontSizes[FLASH_CARD]}
      />
      <ContentEditor
        source="question"
        label="Backside"
        backgroundColor={field.value.hex ? field.value : { hex: field.value }}
        fontColor="#fff"
        defaultFontSize={defaultFontSizes[FLASH_CARD]}
      />
      <ColorInput
        label="Color"
        helperText="Below, you can change the background color of the flashcard. Default color is already set."
        value={field.value}
        onChange={field.onChange}
      />
    </>
  );
};
