import { Editor, Transforms } from "slate";
import {
  BLANK,
  DROPDOWN,
  EXAMPLE,
  LISTEN,
  MICROPHONE,
  POPUP,
  SPEAKER,
} from "./constants";

export const CustomEditor = {
  insertEmoji(editor, emoji) {
    Transforms.insertText(editor, emoji.emoji);
  },
  insertMicrophone(editor) {
    Transforms.insertNodes(editor, {
      type: MICROPHONE,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  toggleMark(editor, format) {
    const isActive = this.isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  },
  getCurrentColor(editor) {
    try {
      const marks = Editor.marks(editor);
      return marks["color"] ? marks["color"] : "#000";
    } catch (e) {
      return "#000";
    }
  },
  getCurrentFontSize(editor) {
    try {
      const marks = Editor.marks(editor);
      return marks["fontSize"] ? marks["fontSize"] : "20px";
    } catch (e) {
      return "20px";
    }
  },
  setColorMark(editor, color) {
    Editor.addMark(editor, "color", color);
  },
  setFontSizeMark(editor, fontSize) {
    Editor.addMark(editor, "fontSize", fontSize);
  },
  isMarkActive(editor, format) {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  },
  insertSpeaker(editor, text) {
    Transforms.insertNodes(editor, {
      type: SPEAKER,
      speakerText: text,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  setTextAlign(editor, textAlign) {
    Transforms.setNodes(editor, { textAlign });
  },
  updateSpeaker(editor, text) {
    Transforms.delete(editor);
    this.insertSpeaker(editor, text);
  },
  insertListen(editor, text) {
    Transforms.insertNodes(editor, {
      type: LISTEN,
      listenText: text,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  updateListen(editor, text) {
    Transforms.delete(editor);
    this.insertListen(editor, text);
  },
  insertPopup(editor, popupid, afterQuestion) {
    Transforms.insertNodes(editor, {
      type: POPUP,
      popupid: popupid,
      afterQuestion: afterQuestion,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  updatePopup(editor, id, afterQuestion) {
    Transforms.delete(editor);
    this.insertPopup(editor, id, afterQuestion);
  },
  insertExample(editor, word, examples) {
    Transforms.insertNodes(editor, {
      type: EXAMPLE,
      word: word,
      examples: examples,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  //Delete the current example selected and just insert the new one
  updateExample(editor, word, examples) {
    Transforms.delete(editor);
    this.insertExample(editor, word, examples);
  },
  insertBlank(editor) {
    Transforms.insertNodes(editor, {
      type: BLANK,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      // Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  insertDropdown(editor) {
    Transforms.insertNodes(editor, {
      type: DROPDOWN,
      children: [{ text: "" }],
    });
    setTimeout(() => {
      Transforms.move(editor, { distance: 1, unit: "offset" });
      this.insertBuffer(editor);
    }, 20);
  },
  insertBuffer(editor) {
    Transforms.insertText(editor, "");
  },
};
