import {
  ArrayInput,
  FormDataConsumer,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  SelectInput,
  required,
} from "react-admin";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { AddItemWithDefaultIdButton } from "./AddItemWithDefaultIdButton";

export const DropdownExercise = () => {
  return (
    <>
      <ContentEditor
        source="question"
        label="Please type the questions the user has to fill."
      />
      <NumberInput
        source="maxTryCount"
        label="What is the maximum try count?"
        defaultValue={2}
      />
      <ArrayInput
        source="groupOptions"
        label="Each dropdown is a group option with multiple answer options and one right answer"
      >
        <SimpleFormIterator addButton={<AddItemWithDefaultIdButton />}>
          <ArrayInput source="answerOptions">
            <SimpleFormIterator
              addButton={<AddItemWithDefaultIdButton />}
              inline
            >
              <TextInput source="answer" />
              <NumberInput
                source="count"
                defaultValue={1}
                sx={{ display: "none" }}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              //The reasonning is that during create or edit, there is an undefined; however,
              //during pre-submission processing, all of that gets replaced with an aws link
              console.log(formData);
              //@ts-ignore
              const curSource = getSource("rightAnswers");
              return (
                scopedFormData && (
                  <ArrayInput source={curSource} defaultValue={[{}]}>
                    <SimpleFormIterator
                      disableAdd
                      disableRemove
                      disableReordering
                    >
                      <SelectInput
                        validate={required(
                          "You have not specified all answers. Please do so."
                        )}
                        source={"answerOptionId"}
                        choices={scopedFormData.answerOptions}
                        optionText={"answer"}
                        optionValue={"id"}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
