import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
} from "react-admin";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { CreatePopupDialog } from "./CreatePopupDialog";
import { CustomEditor } from "../../utilities/CustomEditor";
import { EditPopupDialog } from "./EditPopupDialog";

export const PopupDialogForm = ({
  editor,
  popupDialogState,
  setPopupDialogState,
  curPopupValues,
  setCurPopupValues,
}) => {
  //Either defaultValues are supplied or set everything to empty
  const defaultValues = curPopupValues
    ? {
        afterQuestion: curPopupValues.afterQuestion,
        popupid: curPopupValues.popupid,
      }
    : {
        afterQuestion: false,
        popupid: "",
      };

  //Overriding the save (no dataprovider actions needed)
  //if curPopupValues exist means we need to update the current popup
  //else insert a new one
  const onSubmit = (data) => {
    if (curPopupValues) {
      CustomEditor.updatePopup(editor, data.popupid, data.afterQuestion);
    } else {
      CustomEditor.insertPopup(editor, data.popupid, data.afterQuestion);
    }
    handleClose();
  };

  //State of the create and edit dialog boxes
  const [createDialogState, setCreateDialogState] = useState(false);
  const [editDialogState, setEditDialogState] = useState(false);
  const [editDialogId, setEditDialogId] = useState(null);

  const openCreatePopupDialog = () => {
    setCreateDialogState(true);
  };

  const openEditPopupDialog = () => {
    setEditDialogState(true);
  };
  //Handles the closing of the popupdialog box
  //It needs to close it and set the id of the editdialog to null as well as
  //the curpopupvalues to null [cleaning up all data]
  const handleClose = () => {
    setPopupDialogState(false);
    setCurPopupValues(null);
    setEditDialogId(null);
  };

  const PopupInsertToolbar = () => {
    return (
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <SaveButton label="Submit" />
      </Toolbar>
    );
  };

  const EditPopupButton = (id) => {
    return (
      <Button startIcon={<EditIcon />} onClick={openEditPopupDialog}>
        Edit
      </Button>
    );
  };

  const CreatePopupButton = () => {
    return (
      <Button startIcon={<AddIcon />} onClick={openCreatePopupDialog}>
        Create
      </Button>
    );
  };

  return (
    <Dialog open={popupDialogState} onClose={handleClose}>
      <DialogTitle>Pop-up insert form</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select which popup you want to insert or create one by clicking
          on the button
        </DialogContentText>
        <SimpleForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          toolbar={<PopupInsertToolbar />}
        >
          <BooleanInput source="afterQuestion" label="After Question?" />
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <ReferenceInput
              source="popupid"
              reference="popups"
              allowEmpty
              sort={{ field: "updatedAt", order: "DESC" }}
              perPage={10000}
            >
              <SelectInput
                style={{ width: "70%" }}
                optionValue="id"
                optionText={(popup) => {
                  if (popup.en !== null && popup.en.length !== 0) {
                    return popup.en;
                  } else if (popup.ar !== null && popup.ar.length !== 0) {
                    return popup.ar;
                  } else if (popup.fr !== null && popup.fr.length !== 0) {
                    return popup.fr;
                  }
                }}
              />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.popupid) {
                  setEditDialogId(formData.popupid);
                  return <EditPopupButton {...rest} />;
                } else {
                  setEditDialogId(null);
                  return null;
                }
              }}
            </FormDataConsumer>
            <CreatePopupButton />
          </div>
        </SimpleForm>
        <CreatePopupDialog
          createDialogState={createDialogState}
          setCreateDialogState={setCreateDialogState}
        />
        <EditPopupDialog
          editDialogState={editDialogState}
          setEditDialogState={setEditDialogState}
          editDialogId={editDialogId}
          setEditDialogId={setEditDialogId}
        />
      </DialogContent>
    </Dialog>
  );
};
