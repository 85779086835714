import { Button, Link, useRecordContext } from "react-admin";
import ReorderIcon from "@mui/icons-material/Reorder";
export const ExericseReorderButton = () => {
  const record = useRecordContext();
  if (!record || !record.id) {
    return <></>;
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: "/exercisesReorder",
        state: { lessonId: record.id, exercisesData: record.lessonExercises },
      }}
      label="Reorder"
    >
      <ReorderIcon />
    </Button>
  );
};
