import { Button, Link, useRecordContext } from "react-admin";
import AddIcon from "@mui/icons-material/Add";

const getNextExerciseOrder = (lessonRecord: any): number => {
  if (lessonRecord == null || lessonRecord.lessonExercises == null) {
    return 0;
  }
  return (
    Math.max(
      Math.max(...lessonRecord.lessonExercises.map((item: any) => item.order)),
      0
    ) + 1
  );
};

export const CreateExerciseButton = () => {
  const record = useRecordContext();
  if (!record || !record.id) {
    return <></>;
  }
  const curState = {
    record: {
      lessonExercises: [
        { order: getNextExerciseOrder(record), lessonId: record.id },
      ],
    },
  };
  return (
    <Button
      label="Create exercise"
      component={Link}
      to={{
        pathname: "/exercises/create",
        state: curState,
      }}
    >
      <AddIcon />
    </Button>
  );
};
