import { FileInput, FormDataConsumer } from "react-admin";
import React from "react";
import ReactPlayer from "react-player/file";

export const EnhancedVideoInput = ({ source, label }) => {
  return (
    <FileInput source={source} label={label}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          //The reasonning is that during create or edit, there is an undefined; however,
          //during pre-submission processing, all of that gets replaced with an aws link
          console.log(formData);
          console.log(formData.video);
          return formData.video ? (
            <ReactPlayer
              url={formData.video.src ? formData.video.src : formData.video}
              controls
            />
          ) : null;
        }}
      </FormDataConsumer>
    </FileInput>
  );
};
