import { Create, useNotify, useRedirect, useRefresh } from "react-admin";
import { ExerciseFormSkeleton } from "./ExerciseFormSkeleton";
import { formTransformer } from "./exerciseFormTools";
import { MainExerciseStructure } from "./MainExerciseStructure";

export const ExerciseCreate = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (data) => {
    notify(`Changes saved`);
    data?.lessonExercises
      ? redirect("edit", "lessons", data.lessonExercises[0].lessonId)
      : redirect("show");
    refresh();
  };

  /**@todo Delete uploaded media if the create or edit fails or at least update it to contain
   * the API reference
   */
  const onError = (error) => {
    notify(
      typeof error === "string"
        ? error
        : error.message || "ra.notification.http_error",
      { type: "warning" }
    );
  };

  return (
    <Create
      transform={formTransformer}
      mutationOptions={{ onSuccess, onError }}
    >
      <ExerciseFormSkeleton
        MainExerciseStructure={MainExerciseStructure}
        createNoteDialogState={null}
        handleDialogClose={null}
      />
    </Create>
  );
};
