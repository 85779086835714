import { fetchUtils } from "react-admin";

export const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};
