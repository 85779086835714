import {
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  Create,
  SelectInput,
  ReferenceInput,
} from "react-admin";
export const TestimonialCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="text" />
      <NumberInput source="order" />
      <BooleanInput source="showFullName" />
      <ReferenceInput label="User" reference="users" source="userId">
        <SelectInput source="username" optionText="username" optionValue="id" />
      </ReferenceInput>
      <TextInput source="userId" />
    </SimpleForm>
  </Create>
);
