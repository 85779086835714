import {
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  useGetList,
} from "react-admin";

import { useFormContext } from "react-hook-form";
import { getNextOrderValue } from "./utils";
/**
 *
 * @todo Do not allow one course to be put twice or more
 * @todo figure out how to mutate gracefully (without overridng key)
 * @todo test reordering when a lesson can be in more than one course
 */
export const CourseLessonsInput = () => {
  const { getValues, setValue } = useFormContext();
  const { data: fullLessonList, isLoading: fullLessonListLoading } = useGetList(
    "lessons",
    {
      pagination: { page: 1, perPage: 400 },
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );

  //Need to consider whether the courseLesson is already created so as to not
  //increment the value
  const ConditionalOrderInput = ({ source, courseId, ...rest }) => {
    const curInd = source.split(".")[1];
    const curOrderValue = getValues()["courseLessons"][curInd]["order"];
    const alreadyCreated = getValues()["courseLessons"][curInd]["createdAt"]
      ? true
      : false;
    let createdCourseId;
    if (alreadyCreated) {
      createdCourseId = getValues()["courseLessons"][curInd]["courseId"];
    }
    let nextOrderValue =
      !fullLessonListLoading && courseId
        ? getNextOrderValue(fullLessonList, courseId)
        : null;
    if (
      (!alreadyCreated && curOrderValue !== nextOrderValue) ||
      (alreadyCreated && createdCourseId !== courseId)
    ) {
      setValue(`courseLessons[${curInd}].order`, nextOrderValue);
    }
    return (
      <NumberInput key={courseId} source={source} label="Order" {...rest} />
    );
  };

  return (
    <ArrayInput
      source="courseLessons"
      label="Course and order"
      defaultValue={[{ courseId: "" }]}
    >
      <SimpleFormIterator disableClear>
        <ReferenceInput
          source="courseId"
          reference="courses"
          sort={{ field: "level", order: "ASC" }}
        >
          <SelectInput optionText="level" optionValue="id" label="Course" />
        </ReferenceInput>
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) =>
            scopedFormData && getSource && scopedFormData.courseId ? (
              <ConditionalOrderInput
                source={getSource("order")}
                courseId={scopedFormData.courseId}
                {...rest}
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
