import { Autocomplete, TextField } from "@mui/material";

export const FontSizePicker = ({ handleChange, size }) => {
  const standardFontSizeOptions = [
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "36px",
    "48px",
    "72px",
  ];
  return (
    <Autocomplete
      disablePortal
      options={standardFontSizeOptions}
      onChange={(event, newInputValue) => {
        handleChange(newInputValue);
      }}
      value={size}
      /**@todo add design to make this field look others. not design savy */
      renderInput={(params) => (
        <TextField {...params} sx={{ width: "150px" }} label="Size" />
      )}
    />
  );
};
