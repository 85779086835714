import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
} from "react-admin";
export const TestimonialEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput fullWidth source="text" />
      <NumberInput source="order" />
      <BooleanInput source="showFullName" />
      <TextInput source="userId" />
    </SimpleForm>
  </Edit>
);
