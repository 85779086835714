import LanguageDetect from "languagedetect";
import { sentenceCase } from "sentence-case";

export const langDict = { english: "en", arabic: "ar", french: "fr" };

//Need to fix the typing for the langDict
export const getLanguage = (text: string): string => {
  const lngDetector = new LanguageDetect();
  const lang = lngDetector.detect(text, 2);
  return (langDict as any)[lang[0][0]];
};

export const CreateSelectArrayFromEnum = (
  enumType: any
): { id: string; name: string }[] => {
  const dict = Object.values<string>(enumType).map((it: string) => {
    return { id: it, name: sentenceCase(it) };
  });
  return dict;
};

export const stripHtml = (text: string) => {
  if (text == null) {
    return "";
  }
  return text.replace(/<[^>]*>?/gm, "");
};
