import { serialize } from "../customInputs/contentEditor/utilities/serialization";

export const lessonTransformer = (data: any, previousData) => {
  console.log(data);
  console.log("Previous");
  previousData = previousData?.previousData;
  console.log(previousData);
  /**
   * @todo to add back once topics are introduced again
   */
  // for (var i = 0; i < data.translates.length; i++) {
  //   if (data.translates)
  //     data.translates[i].topics = data.translates[i].topics.split(";");
  // }
  data["seoDescription"] = serialize(data["seoDescription"]);
  return {
    ...data,
    previousData: previousData,
  };
};

export const getLessonTypeFromId = (lessonTypesList, id) => {
  return lessonTypesList?.filter((item) => item.id === id)[0].type;
};
