import {
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TabbedForm,
} from "react-admin";

import { useFormContext } from "react-hook-form";
import { complexityChoices } from "../utilities/constants";
import { stripHtml } from "../utilities/utils";

export const IsAdditionalTab = (props) => {
  const { getValues } = useFormContext();
  const formValues = getValues();
  return formValues &&
    formValues.lessonExercises &&
    formValues.lessonExercises[0] &&
    formValues.lessonExercises[0].lessonId ? (
    <TabbedForm.Tab label="Is Additional?" {...props}>
      <ReferenceInput
        source="additionalForId"
        reference="exercises"
        filter={{
          "lessonExercises.lessonId||$eq||":
            formValues.lessonExercises[0].lessonId,
        }}
        sort={{ field: "lessonExercises.order", order: "ASC" }}
        page={1}
        perPage={100}
      >
        <SelectInput
          optionText={(text) =>
            text
              ? text.lessonExercises[0].order + " - " + stripHtml(text.content)
              : text
          }
          optionValue="id"
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData && formData.additionalForId ? (
            <SelectInput
              source="complexity"
              choices={complexityChoices}
              {...rest}
            />
          ) : (
            <></>
          )
        }
      </FormDataConsumer>
    </TabbedForm.Tab>
  ) : (
    <></>
  );
};
