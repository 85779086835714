import { FunctionField } from "react-admin";
import { stripHtml } from "../utilities/utils";

export const HTMLTextField = ({ source }) => {
  return (
    <FunctionField
      source={source}
      render={(record) => stripHtml(record[source])}
    />
  );
};
