import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { SelectInput, SimpleForm, Title, useGetList } from "react-admin";
import { DraggablePane } from "./LessonDraggablePane";
import { ReorderToolbar } from "./LessonReorderToolbar";

/**
 *
 * @todo To check the dealing with the return Httpstatus values
 */
export const LessonReorder = () => {
  const { data: lessonsData } = useGetList("lessons", {
    pagination: { page: 1, perPage: 500 },
  });
  const { data: coursesData } = useGetList("courses");
  const { data: languagesData } = useGetList("languages");

  //The order of the lessons; defined here and passed to the DraggableList
  const [order, setOrder] = React.useState<any>([]);
  const [currentLessonData, setCurrentLessonData] = React.useState<any>([]);

  return (
    <Card>
      <Title title="Reordering Lessons" />
      <CardContent>
        {/* Yes I know inline styles are ugly but I did not develop any central CSS repo */}
        <div
          style={{
            fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
            padding: "16px",
          }}
        >
          Please select the course for which you would like to reorder the
          lessons
        </div>
        {lessonsData && coursesData && languagesData ? (
          <>
            <SimpleForm
              toolbar={
                <ReorderToolbar
                  order={order}
                  currentData={currentLessonData}
                  resource="lessons"
                />
              }
            >
              <SelectInput
                choices={coursesData.sort((a, b) =>
                  a.level < b.level ? -1 : 1
                )}
                source="targetCourse"
                label="Target Course"
                optionText={"level"}
                optionValue={"id"}
              />
              <SelectInput
                choices={languagesData}
                source="targetLanguage"
                label="Target Language"
                optionText={"name"}
                optionValue={"id"}
              />
              <DraggablePane
                order={order}
                setOrder={setOrder}
                currentLessonData={currentLessonData}
                setCurrentLessonData={setCurrentLessonData}
                lessonsData={lessonsData}
              />
            </SimpleForm>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};
