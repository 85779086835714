import {
  BOLD,
  EXAMPLE,
  ITALIC,
  UNDERLINE,
  SPEAKER,
  LISTEN,
  POPUP,
} from "../utilities/constants";
import { CustomEditor } from "../utilities/CustomEditor";
import {
  blankHotKey,
  boldHotKey,
  exampleHotKey,
  italicHotKey,
  microphoneHotKey,
  underlineHotKey,
  dropdownHotKey,
  popupHotKey,
  speakerHotKey,
  listenHotKey,
} from "./hotkeys";

//This checks for shortcuts as defined in the hotkeys file
export const checkShortcuts = (
  e,
  editor,
  setExampleDialogState,
  setPopupDialogState,
  setSpeakerDialogState,
  setListenDialogState
) => {
  if (blankHotKey(e)) {
    e.preventDefault();
    CustomEditor.insertBlank(editor);
  }
  if (microphoneHotKey(e)) {
    e.preventDefault();
    CustomEditor.insertMicrophone(editor);
  }
  if (dropdownHotKey(e)) {
    e.preventDefault();
    CustomEditor.insertDropdown(editor);
  }
  if (boldHotKey(e)) {
    e.preventDefault();
    CustomEditor.toggleMark(editor, BOLD);
  }
  if (italicHotKey(e)) {
    e.preventDefault();
    CustomEditor.toggleMark(editor, ITALIC);
  }
  if (underlineHotKey(e)) {
    e.preventDefault();
    CustomEditor.toggleMark(editor, UNDERLINE);
  }
  if (speakerHotKey(e)) {
    e.preventDefault();
    setSpeakerDialogState(true);
  }
  if (exampleHotKey(e)) {
    e.preventDefault();
    setExampleDialogState(true);
  }
  if (popupHotKey(e)) {
    e.preventDefault();
    setPopupDialogState(true);
  }
  if (listenHotKey(e)) {
    e.preventDefault();
    setListenDialogState(true);
  }
};

export const checkSubsetShortcuts = (
  e,
  editor,
  setExampleDialogState,
  setSpeakerDialogState,
  setListenDialogState
) => {
  if (boldHotKey(e)) {
    e.preventDefault();
    CustomEditor.toggleMark(editor, BOLD);
  }
  if (italicHotKey(e)) {
    e.preventDefault();
    CustomEditor.toggleMark(editor, ITALIC);
  }
  if (underlineHotKey(e)) {
    e.preventDefault();
    CustomEditor.toggleMark(editor, UNDERLINE);
  }
  if (speakerHotKey(e)) {
    e.preventDefault();
    setSpeakerDialogState(true);
  }
  if (exampleHotKey(e)) {
    e.preventDefault();
    setExampleDialogState(true);
  }
  if (listenHotKey(e)) {
    e.preventDefault();
    setListenDialogState(true);
  }
};

export const checkDoubleClick = (
  e,
  editor,
  setExampleDialogState,
  setCurExampleValues,
  setPopupDialogState,
  setCurPopupValues,
  setSpeakerDialogState,
  setCurSpeakerValues,
  setListenDialogState,
  setCurListenValues
) => {
  //Getting the current selection
  const selection = editor.selection;
  let selected = null;
  if (selection !== null && selection.anchor !== null) {
    selected =
      editor.children[selection.anchor.path[0]].children[
        selection.anchor.path[1]
      ];
  } else {
    selected = null;
  }
  switch (selected.type) {
    case EXAMPLE:
      setCurExampleValues(selected);
      setExampleDialogState(true);
      return;
    case SPEAKER:
      setCurSpeakerValues(selected);
      setSpeakerDialogState(true);
      return;
    case LISTEN:
      setCurListenValues(selected);
      setListenDialogState(true);
      return;
    case POPUP:
      setCurPopupValues(selected);
      setPopupDialogState(true);
      return;
    default:
      return;
  }
};

export const checkSubsetDoubleClick = (
  e,
  editor,
  setExampleDialogState,
  setCurExampleValues,
  setSpeakerDialogState,
  setCurSpeakerValues,
  setListenDialogState,
  setCurListenValues
) => {
  //Getting the current selection
  const selection = editor.selection;
  let selected = null;
  if (selection !== null && selection.anchor !== null) {
    selected =
      editor.children[selection.anchor.path[0]].children[
        selection.anchor.path[1]
      ];
  } else {
    selected = null;
  }
  switch (selected.type) {
    case EXAMPLE:
      setCurExampleValues(selected);
      setExampleDialogState(true);
      return;
    case SPEAKER:
      setCurSpeakerValues(selected);
      setSpeakerDialogState(true);
      return;
    case LISTEN:
      setCurListenValues(selected);
      setListenDialogState(true);
      return;
    default:
      return;
  }
};
