import {
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  useGetList,
} from "react-admin";
import { TranslatesDefaultValue } from "../exercises/defaultValues";
import { getLanguageId, getLanguageName } from "../iframetools/common";
import { LanguageAwareTextInput } from "./LanguageAwareInput";

export const TranslatesDescription = ({
  listOfInputs,
  defaultValues,
  label,
}: {
  listOfInputs: string[];
  defaultValues?: TranslatesDefaultValue;
  label?: string;
}) => {
  const { data: languagesList, isLoading: languageListLoading } =
    useGetList("languages");

  return languageListLoading ? null : (
    <ArrayInput
      source="translates"
      label={label ? label : "translates"}
      defaultValue={languagesList
        ?.filter((item) => item.name !== "fr")
        .map((item) => {
          return {
            languageId: item.id,
          };
        })}
      helperText="Delete the group translate that you do not need to fill."
      sx={{ width: "100%" }}
    >
      <SimpleFormIterator fullWidth disableReordering>
        <ReferenceInput
          label="Language"
          source={"languageId"}
          reference="languages"
        >
          <SelectInput
            label="Language"
            optionText="name"
            optionValue="id"
            defaultValue={getLanguageId("en", languagesList)}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) =>
            scopedFormData && getSource && scopedFormData.languageId
              ? listOfInputs.map((inpSrc) => {
                  return (
                    <LanguageAwareTextInput
                      source={getSource(inpSrc)}
                      label={label ? label : inpSrc}
                      languagesList={languagesList}
                      langId={scopedFormData.languageId}
                      defaultValue={
                        //@ts-ignore
                        defaultValues
                          ? //@ts-ignore
                            defaultValues[
                              getLanguageName(
                                scopedFormData.languageId,
                                languagesList
                              )
                            ]
                          : null
                      }
                      {...rest}
                    />
                  );
                })
              : null
          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
