import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { Title, useGetMany } from "react-admin";
import { useLocation } from "react-router";
import { DraggableList } from "./ExerciseDraggableList";
import { ExericseReorderItemType } from "./ExerciseReorderItemType";
import { ReorderToolbar } from "../Lessons/LessonReorderToolbar";

/**
 *
 * @todo To check the dealing with the return Httpstatus values
 * @todo refactor the return type objects making it more agnostic
 * of the underlying data being dealt with (id being lessonExercise id)
 * and title and subtitle (which only exist on lessons not exercises)
 */
export const ExerciseReorder = () => {
  const location: any = useLocation();
  const exercisesData = location.state.exercisesData;

  //The order of the exercises; defined here and passed to the DraggableList
  const [order, setOrder] = React.useState<any>([]);

  const [currentExercisesData, setCurrentExercisesData] = React.useState<
    ExericseReorderItemType[]
  >([]);

  useGetMany(
    "exercises",
    {
      ids: exercisesData.map((item) => item.exerciseId),
    },
    {
      onSuccess: (data) => {
        //@ts-ignore
        const processedExercisesData = data.map((curExercise) => {
          let currentLessonExercise = exercisesData.filter((curLE) => {
            return curLE.exerciseId === curExercise.id;
          })[0];
          let retObject: ExericseReorderItemType = {
            id: currentLessonExercise.id,
            content: curExercise.content,
            question: curExercise.question,
            description: curExercise.description,
            order: currentLessonExercise.order,
          };
          return retObject;
        });
        processedExercisesData.sort(function (a, b) {
          return a.order - b.order;
        });
        setCurrentExercisesData(processedExercisesData);
        console.log(processedExercisesData);
        setOrder(processedExercisesData.map((_, index) => index));
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <Card>
      <Title title="Reordering Exercises" />
      <CardContent>
        {/* Yes I know inline styles are ugly but I did not develop any central CSS repo */}
        <div
          style={{
            fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
            padding: "16px",
          }}
        >
          Please reorder the exercises for the specified lesson
        </div>
        {currentExercisesData ? (
          <>
            <DraggableList
              items={currentExercisesData}
              order={order}
              setOrder={setOrder}
            />
          </>
        ) : null}
      </CardContent>
      <ReorderToolbar
        order={order}
        currentData={currentExercisesData}
        resource="exercises"
      />
    </Card>
  );
};
