import { Book } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FilterList, FilterListItem, useGetList } from "react-admin";
import { sentenceCase } from "sentence-case";

export const LessonsFilters = () => {
  const { data: coursesData } = useGetList("courses");
  const { data: lessonTypesData } = useGetList("lessonTypes");

  return (
    <>
      <Button variant="contained" href="#/lessonReorder">
        Reorder
      </Button>
      <FilterList label="Course" icon={<Book />}>
        {coursesData
          ?.sort((a, b) => a.level.localeCompare(b.level))
          .map((course) => (
            <FilterListItem
              label={course.level}
              key={course.level}
              value={{ "courseLessons.course.level||$eq||": course.level }}
            />
          ))}
      </FilterList>
      <FilterList label="Lesson Type" icon={<Book />}>
        {lessonTypesData?.map((lessonType) => (
          <FilterListItem
            label={sentenceCase(lessonType.type)}
            key={lessonType.type}
            value={{ "lessonType.type||$eq||": lessonType.type }}
          />
        ))}
      </FilterList>
    </>
  );
};
