import {
  Edit,
  TextInput,
  SelectInput,
  SimpleForm,
  RaRecord,
  useGetIdentity,
  ReferenceInput,
} from "react-admin";
import { NotePriority, NoteStatus } from "../utilities/enums";
import { CreateSelectArrayFromEnum } from "../utilities/utils";

export const ResourceNoteEdit = ({ redirect }) => {
  const priorityDict = CreateSelectArrayFromEnum(NotePriority);
  const statusDict = CreateSelectArrayFromEnum(NoteStatus);
  const { data: identity } = useGetIdentity();

  const beforeSubmission = async (data: RaRecord) => {
    return { ...data, userId: identity?.id };
  };

  return (
    <Edit transform={beforeSubmission}>
      <SimpleForm>
        <TextInput source="text" multiline fullWidth />
        <SelectInput source="priority" choices={priorityDict} />
        <SelectInput source="status" choices={statusDict} />
        <ReferenceInput
          label="Assigned to"
          reference="panelUsers"
          source="assignedToPanelUserId"
        >
          <SelectInput fullWidth optionText={"username"} optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
