import { FileInput, FormDataConsumer } from "react-admin";

export const EnhancedImageInput = ({ source, label }) => {
  return (
    <FileInput source={source} label={label}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          //The reasonning is that during create or edit, there is an undefined; however,
          //during pre-submission processing, all of that gets replaced with an aws link
          return formData.image && !formData.image.hex ? (
            <img
              alt={"Lesson"}
              style={{ maxWidth: "500px" }}
              src={formData.image.rawFile ? formData.image.src : formData.image}
              // other props here
            />
          ) : null;
        }}
      </FormDataConsumer>
    </FileInput>
  );
};
