export const uploadFile = async (file) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  const formData = new FormData();
  formData.append("file", file.rawFile);

  const ret = await fetch(`${process.env.REACT_APP_BACKEND}/files`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    method: "POST",
    body: formData,
  });
  const jsonResp = await ret.json();
  return jsonResp;
};

export const deleteFile = async (fileUrl) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  const ret = await fetch(`${process.env.REACT_APP_BACKEND}/files`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    method: "DELETE",
    body: JSON.stringify({ fileUrl }),
  });
  return ret;
};
