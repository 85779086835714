import { Button, Link, useRecordContext } from "react-admin";
import LinkIcon from "@mui/icons-material/Link";

export const GoToResourceButton = () => {
  const record = useRecordContext();
  const mapTypeToUrl = {
    COURSE: "courses",
    EXERCISE: "exercises",
    LESSON: "lessons",
  };
  return (
    <Button
      resource="exercises"
      component={Link}
      to={{
        pathname: `/${mapTypeToUrl[record.resource]}/${record.resourceId}`,
      }}
      label="Go to resource"
    >
      <LinkIcon />
    </Button>
  );
};
