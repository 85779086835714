import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";

export const ReadTextExercise = () => {
  return (
    <>
      <ContentEditor
        source="content"
        label="The text to be shown to the user."
      />
    </>
  );
};
