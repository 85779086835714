import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  FunctionField,
  DateField,
  NumberField,
} from "react-admin";
import { sentenceCase } from "sentence-case";
import { HTMLTextField } from "../../customInputs/HTMLTextField";
import { complexityChoices } from "../../utilities/constants";
import { ExercisesFilterAside } from "./filter/ExericseFilterAside";

export const ExerciseList = (props: any) => {
  const postFilters = [
    <TextInput label="Search content" source="content" alwaysOn />,
    <TextInput label="Search description" source="description" alwaysOn />,
    <TextInput label="Search question" source="question" alwaysOn />,
  ];

  return (
    <List {...props} filters={postFilters} aside={<ExercisesFilterAside />}>
      <Datagrid rowClick="edit">
        <ReferenceField
          source="exerciseCategoryId"
          reference="exerciseCategories"
          link={false}
        >
          <FunctionField render={(record) => sentenceCase(record.name)} />
        </ReferenceField>
        <ReferenceField
          source="exerciseTypeId"
          reference="exerciseTypes"
          link={false}
        >
          <FunctionField render={(record) => sentenceCase(record.type)} />
        </ReferenceField>
        <DateField source="updatedAt" label="Last updated" showTime />
        <HTMLTextField source="content" />
        <HTMLTextField source="description" />
        <HTMLTextField source="question" />
        <ArrayField
          source="groupOptions[0].answerOptions"
          label="Answer Options"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="answer" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField
          source="groupOptions[0].rightAnswers"
          label="Right Answer(s)"
        >
          <SingleFieldList linkType={false}>
            <ReferenceField
              source="answerOptionId"
              reference="answerOptions"
              link={false}
            >
              <ChipField source="answer" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
        <ArrayField
          label="Version"
          source="lessonExercises"
          sortBy="lessonExercises.version"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="version" />
          </SingleFieldList>
        </ArrayField>

        <ArrayField
          label="Order"
          source="lessonExercises"
          sortBy="lessonExercises.order"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="order" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="maxTryCount" label="Maximum Tries" />

        <FunctionField
          source="complexity"
          render={(record) =>
            complexityChoices.filter((item) => item.id === record.complexity)[0]
              .name
          }
        />
      </Datagrid>
    </List>
  );
};
