import { TextField, useRecordContext } from "react-admin";
import { NotePriority } from "../../utilities/enums";

export const ColoredPriorityField = ({ source }) => {
  const record = useRecordContext();
  const colorMap = {
    [NotePriority.NICE_TO_ADD]: "#695086",
    LOW: "seagreen",
    NORMAL: "dodgerblue",
    HIGH: "orange",
    URGENT: "maroon",
  };
  return (
    <TextField
      sx={{
        color: record[source] ? colorMap[record[source]] : "",
        fontWeight: "bold",
      }}
      source={source}
    />
  );
};
