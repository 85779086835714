import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
} from "react-admin";

export const TestimonialList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="text" />
      <NumberField source="order" />
      <BooleanField source="showFullName" />
      <ReferenceField label="User" reference="users" source="userId">
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);
