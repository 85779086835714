import React from "react";
import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import clamp from "lodash.clamp";
import swap from "lodash-move";
import { OrderItemType } from "./LessonOrderItemType";
import "./LessonReorderComponents.css";

const itemHeight = 100;

//Replicated from the example on useSprings from react-spring
const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: curIndex * (itemHeight * 1.2) + y,
          scale: 1.1,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === "y" || key === "zIndex",
        }
      : {
          y: order.indexOf(index) * (itemHeight * 1.2),
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

export const DraggableList = ({
  items,
  order,
  setOrder,
}: {
  items: any[];
  order: number[];
  setOrder: any;
}) => {
  const [springs, api] = useSprings(items.length, fn(order)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.indexOf(originalIndex);
    const curRow = clamp(
      Math.round((curIndex * 100 + y) / 100),
      0,
      items.length - 1
    );
    const newOrder = swap(order, curIndex, curRow);
    api.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) {
      console.log("Setting new order");
      console.log(items);
      setOrder(newOrder);
      console.log(newOrder);
    }
  });
  return (
    <div
      className="content"
      style={{
        height: items.length * itemHeight * 1.2,
        minWidth: "300px",
        width: "500px",
      }}
    >
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            zIndex,
            fontSize: 16,
            height: itemHeight,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
            ),
            y,
            scale,
          }}
          children={`${order.indexOf(i) + 1} - ${items[i].title} - ${
            items[i].description
          }`}
        />
      ))}
    </div>
  );
};
