import {
  BLANK,
  DROPDOWN,
  MICROPHONE,
  EXAMPLE,
  POPUP,
  SPEAKER,
  LISTEN,
  DICTIONARY,
} from "../utilities/constants";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const BlankElement = (props) => {
  return <span {...props.attributes}>{props.children}_____</span>;
};

const MicrophoneElement = (props) => {
  return <span {...props.attributes}>{props.children}&#127908;</span>;
};

const DropdownElement = (props) => {
  return <span {...props.attributes}>{props.children}[SELECT]</span>;
};

const SpeakerElement = (props) => {
  return <span {...props.attributes}>{props.children}&#128266;</span>;
};

const DictionaryElement = (props) => {
  return (
    <span {...props.attributes} style={{ color: "#cc99ff" }}>
      {props.children}
      {props.element.dictionaryText}
    </span>
  );
};

const ListenElement = (props) => {
  return (
    <span {...props.attributes} style={{ color: "#ff6600" }}>
      {props.children}
      {props.element.listenText}
    </span>
  );
};

const ExampleElement = (props) => {
  return (
    <span {...props.attributes} style={{ backgroundColor: "#5b4676" }}>
      {props.children}
      {props.element.word}
    </span>
  );
};

const PopupElement = (props) => {
  return (
    <span {...props.attributes} style={{ backgroundColor: "#5b4676" }}>
      <FormatQuoteIcon color="#5b4676" />
      {props.children}
    </span>
  );
};

const DefaultElement = (props) => {
  return (
    <div
      {...props.attributes}
      style={{
        textAlign: props.element.textAlign ? props.element.textAlign : "none",
      }}
    >
      {props.children}
    </div>
  );
};

export const Element = (props) => {
  switch (props.element.type) {
    case BLANK:
      return <BlankElement {...props} />;
    case MICROPHONE:
      return <MicrophoneElement {...props} />;
    case DROPDOWN:
      return <DropdownElement {...props} />;
    case EXAMPLE:
      return <ExampleElement {...props} />;
    case POPUP:
      return <PopupElement {...props} />;
    case SPEAKER:
      return <SpeakerElement {...props} />;
    case LISTEN:
      return <ListenElement {...props} />;
    case DICTIONARY:
      return <DictionaryElement {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
};
