import { FormDataConsumer, TextInput, useGetList } from "react-admin";
import { getLessonTypeFromId } from "../lessonFormTools";

export const ConditionalVideoInput = () => {
  const { data: lessonTypesList, isLoading: lessonTypesLoading } =
    useGetList("lessonTypes");

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (!lessonTypesLoading && formData.lessonTypeId) {
          const curLessonType = getLessonTypeFromId(
            lessonTypesList,
            formData.lessonTypeId
          );
          return curLessonType === "video" ? (
            <TextInput
              fullWidth
              source="video"
              label="Video link"
              helperText="Add the link for the video"
              {...rest}
            />
          ) : null;
        }
      }}
    </FormDataConsumer>
  );
};
