import {
  BLANK,
  MICROPHONE,
  POPUP,
  EXAMPLE,
  LISTEN,
  SPEAKER,
  DICTIONARY,
  DROPDOWN,
} from "../utilities/constants";

const inlineElements = [
  BLANK,
  MICROPHONE,
  POPUP,
  EXAMPLE,
  LISTEN,
  SPEAKER,
  DICTIONARY,
  DROPDOWN,
];
const voidElements = [
  BLANK,
  MICROPHONE,
  EXAMPLE,
  LISTEN,
  SPEAKER,
  DROPDOWN,
  POPUP,
];

//This will override the inline and void functions
//and set the elements defined above as inline and/or void
export const withModInlineAndVoid = (editor) => {
  const { isInline, isVoid } = editor;
  editor.isInline = (element) => {
    if (inlineElements.includes(element.type)) return true;
    else {
      return isInline(element);
    }
  };

  editor.isVoid = (element) => {
    if (voidElements.includes(element.type)) return true;
    else {
      return isVoid(element);
    }
  };

  return editor;
};
