import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  EditButton,
} from "react-admin";
import { ColoredPriorityField } from "./components/ColoredPriorityField";
import { GoToResourceButton } from "./components/GoToResourceButton";
import { ResolveResourceNoteButton } from "./components/ResolveResourceNoteButton";
import { ResourceNoteListActions } from "./components/ResourceNoteListActions";
import { ResourceNoteListAside } from "./components/ResourceNoteListAside";
import { resourceNoteListFilters } from "./components/ResourceNoteListFilter";

export const ResourceNoteList = () => (
  <List
    title="Resource Notes"
    actions={<ResourceNoteListActions />}
    filters={resourceNoteListFilters}
    aside={<ResourceNoteListAside />}
    sort={{ field: "updatedAt", order: "DESC" }}
    storeKey={false}
  >
    <Datagrid>
      <DateField source="updatedAt" showTime />
      <ReferenceField
        label="Created by"
        source="createdByPanelUserId"
        reference="panelUsers"
      >
        <TextField source="username" emptyText="N.A." />
      </ReferenceField>
      <ReferenceField
        label="Assigned to"
        source="assignedToPanelUserId"
        reference="panelUsers"
      >
        <TextField source="username" emptyText="N.A." />
      </ReferenceField>

      <TextField source="resource" />
      <ColoredPriorityField source="priority" />
      <TextField source="status" />
      <TextField source="text" />
      <GoToResourceButton />

      <DateField
        source="resolvedAt"
        emptyText="N.A."
        showTime
        sortable={false}
      />
      <ReferenceField
        label="Resolved by"
        source="resolvedByPanelUserId"
        reference="panelUsers"
        emptyText="N.A."
        sortable={false}
      >
        <TextField source="username" />
      </ReferenceField>
      <EditButton />
      <ResolveResourceNoteButton />
    </Datagrid>
  </List>
);
