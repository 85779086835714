import { NumberInput } from "react-admin";

export const LessonDurationNumberInput = () => {
  return (
    <NumberInput
      source="timeDurationSeconds"
      label="Expected time for this lesson"
      helperText="Input the expected time for this lesson in minutes"
      parse={(v) => v * 60}
      format={(v) => v / 60}
      defaultValue={600}
    />
  );
};
