//Takes in the data array and returns it in a format to be passed to the iFrame
//Note that the current function does not accept languages other than English
//And arabic

import { transposeAbout } from "./common";

/**
 * @todo Add enums and strings to avoid string errors for stuff like type and title
 * @param lang is the current language that is being displayed
 * @param languagesArray is the set of languages and their ids which cannot be determined prehoc
 */
export default function prepareCoursePostMessage(data, lang, languagesArray) {
  //

  //Define the post Message dictionary that would be serialized later on
  let postMessage = {};
  postMessage["type"] = "IFRAME_PREVIEW/COURSES";

  //Since the payload is also deeply nested, it is defined individually
  let postMessagePayload = {};
  postMessagePayload.data = {};
  postMessagePayload.language = lang;

  //For each language, loop over the array of data and get all related translates
  //These translates should be placed in the appropriate structure that the iframe is expecting
  const curPayloadData = [];
  data.forEach((item) => {
    const internalItemData = {};
    internalItemData.level = item.level;
    internalItemData.title = transposeAbout(
      item.translates,
      languagesArray,
      "title"
    );
    internalItemData.description = transposeAbout(
      item.translates,
      languagesArray,
      "description"
    );
    curPayloadData.push(internalItemData);
  });
  postMessagePayload.data = curPayloadData;
  postMessage.payload = postMessagePayload;

  //Returned a stringified version of the postMessage
  return JSON.stringify(postMessage);
}
