import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TabbedForm,
  TextField,
  useRecordContext,
} from "react-admin";
import { expandedDataProvider } from "../utilities/dataprovider";
import { useQuery } from "react-query";
import { ColoredPriorityField } from "./components/ColoredPriorityField";
import { GoToResourceButton } from "./components/GoToResourceButton";
import { ResourceNoteListActions } from "./components/ResourceNoteListActions";
import { ResourceNoteListAside } from "./components/ResourceNoteListAside";
import { ResolveResourceNoteButton } from "./components/ResolveResourceNoteButton";

export const ResourceNoteFormTab = ({ resourceType, ...props }) => {
  const record = useRecordContext();
  const { data, isLoading } = useQuery(
    ["notes", { id: record.id.toString(), resourceType }],
    () => {
      return expandedDataProvider.getResourceNotes(
        record.id.toString(),
        resourceType,
        true,
        { filter: { "status||$neq||": "RESOLVED" } }
      );
    }
  );
  let label = "Open Issues";
  if (!isLoading) {
    if (data?.total > 0) {
      label += ` (${data.total})`;
    } else {
      label += ` - None :)`;
    }
  }

  return (
    <TabbedForm.Tab label={label} {...props}>
      <List
        resource="resourceNotes"
        actions={<ResourceNoteListActions />}
        aside={<ResourceNoteListAside />}
        sort={{ field: "updatedAt", order: "DESC" }}
        storeKey={false}
        //@ts-ignore
        queryOptions={{
          meta: {
            related: true,
            resourceId: record.id.toString(),
            resourceType,
            deep: true,
            onlyOpenIssues: false,
          },
        }}
      >
        <Datagrid>
          <DateField source="updatedAt" showTime />
          <ReferenceField
            label="Created by"
            source="createdByPanelUserId"
            reference="panelUsers"
          >
            <TextField source="username" emptyText="N.A." />
          </ReferenceField>
          <ReferenceField
            label="Assigned to"
            source="assignedToPanelUserId"
            reference="panelUsers"
          >
            <TextField source="username" emptyText="N.A." />
          </ReferenceField>
          <TextField source="resource" />
          <ColoredPriorityField source="priority" />
          <TextField source="status" />
          <TextField source="text" />
          <GoToResourceButton />
          <DateField
            source="resolvedAt"
            emptyText="N.A."
            showTime
            sortable={false}
          />
          <ReferenceField
            label="Resolved by"
            source="resolvedByPanelUserId"
            reference="panelUsers"
            emptyText="N.A."
            sortable={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <EditButton />
          <ResolveResourceNoteButton />
        </Datagrid>
      </List>
    </TabbedForm.Tab>
  );
};
