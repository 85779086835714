import { FormDataConsumer, NumberInput, useGetList } from "react-admin";
import { getLessonTypeFromId } from "../lessonFormTools";

export const ConditionalLessonLimitInput = () => {
  const { data: lessonTypesList, isLoading: lessonTypesLoading } =
    useGetList("lessonTypes");

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (!lessonTypesLoading && formData.lessonTypeId) {
          const curLessonType = getLessonTypeFromId(
            lessonTypesList,
            formData.lessonTypeId
          );
          return curLessonType === "exam" || curLessonType === "final_exam" ? (
            <NumberInput
              source="nextTryInSeconds"
              label="Time between attempts"
              helperText="If left empty, the exam would be immediately accessible after finishing it; otherwise input the number of seconds"
            />
          ) : null;
        }
      }}
    </FormDataConsumer>
  );
};
