import { Editor } from "slate";
import {
  basicArabicPattern,
  basicEnglishPattern,
} from "../utilities/alignment";
import { DIR, LTR, RTL } from "../utilities/constants";

export const withInsert = (editor) => {
  const { insertText } = editor;

  editor.insertText = (text) => {
    const curPath = editor.selection.anchor.path;
    const curNodeDir = editor.children[curPath[0]].children[curPath[1]].dir;
    if (curNodeDir === RTL && basicEnglishPattern.test(text)) {
      Editor.addMark(editor, DIR, LTR);
      insertText(text);
    } else if (curNodeDir === LTR && basicArabicPattern.test(text)) {
      Editor.addMark(editor, DIR, RTL);
      insertText(text);
    } else {
      insertText(text);
    }
    return;
  };

  return editor;
};
