import {
  INSTRUCTION,
  INTONATION,
  TITLE,
  FLASH_CARD,
  FLIP_CARD,
  READ_TEXT,
  READ_TEXT_WITH_XP,
  MULTIPLE_CHOICE_VERTICAL,
  MULTIPLE_CHOICE_HORIZONTAL,
  INTONATION_CHECK,
  DROWNING_MAN,
  DRAG_AND_DROP,
  SHORT_ANSWER,
  DROPDOWN,
  LISTEN_AND_SPEAK,
  MATCH_THE_WORDS,
  WHAT_DO_YOU_DO_ON_THE_WEEKEND,
  ENGLISH,
  ARABIC,
} from "../utilities/constants";

export const intonationDefaultValue = [
  {
    type: "paragraph",
    children: [
      {
        color: "#f8e71c",
        text: "What is Ariana saying?",
        dir: "ltr",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: " ",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: "{Statement}",
        dir: "ltr",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        color: "#417505",
        text: "Intended meaning:",
        dir: "ltr",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: " ",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: "{Meaning}",
        dir: "ltr",
      },
    ],
  },
];

export const defaultFontSizes = {
  [INSTRUCTION]: "20px",
  [INTONATION]: "20px",
  [TITLE]: "20px",
  [FLASH_CARD]: "20px",
  [FLIP_CARD]: "20px",
  [READ_TEXT]: "20px",
  [READ_TEXT_WITH_XP]: "20px",
  [MULTIPLE_CHOICE_VERTICAL]: "20px",
  [MULTIPLE_CHOICE_HORIZONTAL]: "20px",
  [INTONATION_CHECK]: "20px",
  [DRAG_AND_DROP]: "20px",
  [SHORT_ANSWER]: "20px",
  [DROPDOWN]: "20px",
  [LISTEN_AND_SPEAK]: "20px",
  [MATCH_THE_WORDS]: "20px",
  [WHAT_DO_YOU_DO_ON_THE_WEEKEND]: "20px",
  [DROWNING_MAN]: "20px",
};

export const FLASHCARD_PURPLE_COLOR = "#584873";

export const DEFAULT_FONT_SIZE = "20px";
export const DEFAULT_POPUP_FONT_SIZE = "16px";

export const FLASH_CARD_INSTRUCTION_DEFAULT_VALUES: TranslatesDefaultValue = {
  [ENGLISH]: "Read this slide",
  [ARABIC]: "اقرأ",
};

export const OVERALL_INSTRUCTION_DEFAULT_VALUES: TranslatesDefaultValue = {
  [ENGLISH]: "Read this slide",
  [ARABIC]: "اقرأ",
};
export interface TranslatesDefaultValue {
  [ENGLISH]: string;
  [ARABIC]: string;
}
