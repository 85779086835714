import EditorButton from "./Button";
import MicIcon from "@mui/icons-material/MicOutlined";

export const MicButton = ({ onMouseDown }) => {
  return (
    <EditorButton onMouseDown={onMouseDown} border={true}>
      <MicIcon />
    </EditorButton>
  );
};
