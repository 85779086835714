import { Editor, Text, Transforms } from "slate";
import { DIR } from "./constants";

//Aims to set the inter-language spaces to always be in a node with the same
//dir as the overallTextAlign >> this is to avoid both start and finish spaces being on one side
//only such as john  احمدgeorge
//The two methods are split as mutating the same info twice is unstable >> need to
//call each indepdent of other
//Unclear why must call twice; too tired to figure out
export const fixOverallSpaces = (editor: Editor) => {
  fixOverallSpacesEnd(editor);
  fixOverallSpacesEnd(editor);
  fixOverallSpacesStart(editor);
  fixOverallSpacesStart(editor);
};

//These are characters that can surround nodes and do not need spaces
const safeStartCharsPattern = /^[([<"{}'“”>\]).]/;
const safeEndCharsPattern = /[([<"{}'“”>\]).]$/;

const fixOverallSpacesStart = (editor: Editor) => {
  for (let i = 0; i < editor.children.length; i++) {
    const row = editor.children[i];
    //@ts-ignore
    fixLineSpacesStart(editor, row.children, i, row.textAlign);
  }
};

const fixOverallSpacesEnd = (editor: Editor) => {
  for (let i = 0; i < editor.children.length; i++) {
    const row = editor.children[i];
    //@ts-ignore
    fixLineSpacesEnd(editor, row.children, i, row.textAlign);
  }
};

const fixLineSpacesStart = (editor, row, rowInd, textAlign) => {
  if (row.length === 0) {
    return;
  }
  //The basedir always matches whatever the first element dir is!
  const baseDir = row[0].dir;
  for (let i = 0; i < row.length; i++) {
    const curNode = row[i];
    if (!Text.isText(curNode)) {
      continue;
    }
    if (curNode[DIR] !== baseDir) {
      if (i > 0) {
        if (
          Text.isText(row[i - 1]) &&
          !row[i - 1].text.endsWith(" ") &&
          !safeEndCharsPattern.test(row[i - 1].text)
        ) {
          Transforms.insertText(editor, " ", {
            at: {
              path: [rowInd, i - 1],
              offset: row[i - 1].text.length,
            },
          });
        }
      }
    }
  }
};

const fixLineSpacesEnd = (editor, row, rowInd, textAlign) => {
  if (row.length === 0) {
    return;
  }
  //The basedir always matches whatever the first element dir is!
  const baseDir = row[0].dir;
  for (let i = 0; i < row.length; i++) {
    const curNode = row[i];
    if (!Text.isText(curNode)) {
      continue;
    }

    if (curNode[DIR] !== baseDir) {
      if (i < row.length - 1) {
        const spacesAtEndLength =
          curNode.text.length - curNode.text.trimEnd().length;
        Transforms.delete(editor, {
          at: {
            anchor: {
              path: [rowInd, i],
              offset: curNode.text.length - spacesAtEndLength,
            },
            focus: { path: [rowInd, i], offset: curNode.text.length + 1 },
          },
        });
        if (
          Text.isText(row[i + 1]) &&
          !row[i + 1].text.startsWith(" ") &&
          !safeStartCharsPattern.test(row[i + 1].text)
        ) {
          Transforms.insertText(editor, " ", {
            at: {
              path: [rowInd, i + 1],
              offset: 0,
            },
          });
        }
      }
    }
  }
};
