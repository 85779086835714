import {
  DRAG_AND_DROP,
  FLASH_CARD,
  INSTRUCTION,
  INTONATION,
  INTONATION_CHECK,
  MULTIPLE_CHOICE_VERTICAL,
  DROPDOWN,
  SHORT_ANSWER,
  READ_TEXT,
  READ_TEXT_WITH_XP,
  TITLE,
  LISTEN_AND_SPEAK,
  MULTIPLE_CHOICE_HORIZONTAL,
} from "../utilities/constants";
import { TranslatesDescription } from "../customInputs";
import { OVERALL_INSTRUCTION_DEFAULT_VALUES } from "./defaultValues";

export const InstructionsTab = ({ exerciseType }) => {
  switch (exerciseType) {
    case INSTRUCTION:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case TITLE:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case READ_TEXT:
    case READ_TEXT_WITH_XP:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );

    case FLASH_CARD:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case INTONATION:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case INTONATION_CHECK:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case MULTIPLE_CHOICE_VERTICAL:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case MULTIPLE_CHOICE_HORIZONTAL:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case DRAG_AND_DROP:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case DROPDOWN:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case SHORT_ANSWER:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    case LISTEN_AND_SPEAK:
      return (
        <TranslatesDescription
          listOfInputs={["shortDescription"]}
          label="Instructions"
          defaultValues={OVERALL_INSTRUCTION_DEFAULT_VALUES}
        />
      );
    default:
      return <></>;
  }
};
