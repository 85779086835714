import { getResourceNotes } from "../resourceNotesRequests";

export const getList = async (resource, params, dataProvider) => {
  if (resource === "resourceNotes" && params.meta && params.meta.related) {
    const { resourceId, resourceType, deep } = params.meta;
    const data = await getResourceNotes(resourceId, resourceType, deep, params);
    return data;
  }
  return dataProvider.getList(resource, params);
};
