import { useCallback, useState, forwardRef } from "react";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { ExamplesDialogForm } from "./components/dialogs/ExampleDialog";
import { Element } from "./rendering/Element";
import { Leaf } from "./rendering/Leaf";
import { checkDoubleClick, checkShortcuts } from "./events";
import { LessonsToolbar } from "./components/LessonToolbar";
import { PopupDialogForm } from "./components/dialogs/PopupDialog";
import { SpeakerDialogForm } from "./components/dialogs/SpeakerDialog";
import { ListenDialogForm } from "./components/dialogs/ListenDialog";
import { unifyContent } from "./utilities/alignment";
import { useInput } from "react-admin";
import { deserialize } from "./utilities/deserialization";
import { withEnter, withModInlineAndVoid, withInsert } from "./plugins";
import "./ContentEditor.css";
import { getDefaultValue } from "./utilities/defaultValue";
import { DEFAULT_FONT_SIZE } from "../../exercises/defaultValues";
import { Color } from "../ColorInput";

/**
 * @todo Need to add validation such as for required
 * @todo sentenceCase keeps crashing the system for some reason
 * @todo figure out why forwardRef is needed (I have no idea)
 * @returns
 */
export const ContentEditor = forwardRef(
  ({
    label,
    source,
    backgroundColor = { hex: "#f8f8f8" },
    fontColor = "#000",
    defaultValue = undefined,
    defaultFontSize = DEFAULT_FONT_SIZE,
    ...rest
  }: {
    label: string;
    source: string;
    backgroundColor?: Color;
    fontColor?: string;
    defaultValue?: any | undefined;
    defaultFontSize?: string;
    rest?: any;
  }) => {
    // Create a Slate editor object that won't change across renders.
    //https://github.com/ianstormtaylor/slate/pull/3925#issuecomment-781179930
    const [editor] = useState(() =>
      withReact(
        withHistory(withInsert(withModInlineAndVoid(withEnter(createEditor()))))
      )
    );

    const renderElement = useCallback((props) => {
      return <Element {...props} />;
    }, []);

    const renderLeaf = useCallback((props) => {
      return <Leaf {...props} />;
    }, []);

    defaultValue = defaultValue
      ? defaultValue
      : getDefaultValue(defaultFontSize, fontColor);

    const editableContentStyle = {
      background: backgroundColor.hex,
    };

    //This function is needed to add the direction tags for the text
    //It is called with the default onChange using the useInput() Hook by react-admin
    const onSlateEditorChange = (values) => {
      unifyContent(editor, values);
    };

    const deserializationWithDefaultValue = (values) => {
      return deserialize(values, defaultFontSize);
    };

    const { field } = useInput({
      // Pass the event handlers to the hook but not the component as the field property already has them.
      // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
      onChange: onSlateEditorChange,
      defaultValue: defaultValue,
      //Due to some issues, serialization is dealt with at the overall edit or create view
      format: deserializationWithDefaultValue,
      source: source,
      label: label,
      ...rest,
    });

    //Setting the initial state for the different dialogforms
    //@todo >> manage this using react context
    const [speakerDialogState, setSpeakerDialogState] = useState(false);
    const [curSpeakerValues, setCurSpeakerValues] = useState(null);
    const [exampleDialogState, setExampleDialogState] = useState(false);
    const [curExampleValues, setCurExampleValues] = useState(null);
    const [popupDialogState, setPopupDialogState] = useState(false);
    const [curPopupValues, setCurPopupValues] = useState(null);
    const [listenDialogState, setListenDialogState] = useState(false);
    const [curListenValues, setCurListenValues] = useState(null);

    return (
      // Add the editable component inside the context.
      <div
        style={{
          width: "100%",
        }}
      >
        <Slate editor={editor} {...field} {...rest}>
          <LessonsToolbar
            editor={editor}
            setExampleDialogState={setExampleDialogState}
            setPopupDialogState={setPopupDialogState}
            setSpeakerDialogState={setSpeakerDialogState}
            setListenDialogState={setListenDialogState}
          />
          <ExamplesDialogForm
            editor={editor}
            exampleDialogState={exampleDialogState}
            setExampleDialogState={setExampleDialogState}
            curExampleValues={curExampleValues}
            setCurExampleValues={setCurExampleValues}
          />
          <SpeakerDialogForm
            editor={editor}
            speakerDialogState={speakerDialogState}
            setSpeakerDialogState={setSpeakerDialogState}
            curSpeakerValues={curSpeakerValues}
            setCurSpeakerValues={setCurSpeakerValues}
          />
          <PopupDialogForm
            editor={editor}
            popupDialogState={popupDialogState}
            setPopupDialogState={setPopupDialogState}
            curPopupValues={curPopupValues}
            setCurPopupValues={setCurPopupValues}
          />
          <ListenDialogForm
            editor={editor}
            listenDialogState={listenDialogState}
            setListenDialogState={setListenDialogState}
            curListenValues={curListenValues}
            setCurListenValues={setCurListenValues}
          />
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            className="slate-content-editor"
            placeholder={label}
            style={editableContentStyle}
            renderPlaceholder={({ children, attributes }) => (
              <div {...attributes}>
                <span
                  style={{
                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif"`,
                    fontSize: defaultFontSize,
                    color: "#000 !important",
                    lineHeight: "1.4375em",
                    letterSpacing: "0.00938em",
                  }}
                  contentEditable={false}
                >
                  {children}
                </span>
              </div>
            )}
            onKeyDown={(e) => {
              checkShortcuts(
                e,
                editor,
                setExampleDialogState,
                setPopupDialogState,
                setSpeakerDialogState,
                setListenDialogState
              );
            }}
            onDoubleClick={(e) => {
              checkDoubleClick(
                e,
                editor,
                setExampleDialogState,
                setCurExampleValues,
                setPopupDialogState,
                setCurPopupValues,
                setSpeakerDialogState,
                setCurSpeakerValues,
                setListenDialogState,
                setCurListenValues
              );
            }}
          />
        </Slate>
      </div>
    );
  }
);
