//Addimg the JWT token to the headers of the request
//and is added as a second parameter to the dataProvider below

/**
 * @todo Add file handling for  deleteMany for AWS
 * @todo prolly updateMany doesn't need to to support any file actions
 */
import crudProvider from "ra-data-nestjsx-crud";
import { CREATE, UPDATE } from "../constants";
import { getResourceNotes } from "./resourceNotesRequests";
import { getList, preprocessParams } from "./expandedDataProviderMethods";
import { httpClient } from "./expandedDataProviderMethods";

const dataProvider = crudProvider(
  process.env.REACT_APP_BACKEND as string,
  httpClient
);

export const expandedDataProvider = {
  ...dataProvider,
  getList: async (resource, params) => getList(resource, params, dataProvider),
  //Can also test for files using instaceOf file (in case edge cases arise)
  create: async (resource, params) => {
    await preprocessParams(resource, params, CREATE);
    return dataProvider.create(resource, params);
  },
  //Replicating the same above logic for update as well
  update: async (resource, params) => {
    await preprocessParams(resource, params, UPDATE);
    return dataProvider.update(resource, params);
  },
  delete: async (resource, params) => {
    await preprocessParams(resource, params, UPDATE);
    return dataProvider.delete(resource, params);
  },
  getResourceNotes,
};
