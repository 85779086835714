import {
  ArrayInput,
  FormDataConsumer,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  SelectInput,
  required,
} from "react-admin";
import { AudioInput } from "../../customInputs/AudioInput";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { EnhancedImageInput } from "../../customInputs/ImageInput";
import { v4 as uuidv4 } from "uuid";
import { AddItemWithDefaultIdButton } from "./AddItemWithDefaultIdButton";
import { EnhancedVideoInput } from "../../customInputs/VideoInput";

export const ShortAnswerExercise = () => {
  return (
    <>
      <TextInput
        fullWidth
        source="content"
        label={
          "If this is a dictation question, this will be read to the user if no audio file provider. If this is a translation exercise; this will be shown above the Help Me Hint"
        }
      />
      <TextInput fullWidth source="description" label="Text below image" />
      <ContentEditor
        source="question"
        label="The questions the user has to fill. Insert the appropriate number of blanks."
      />
      <AudioInput
        source="audio"
        label="Upload an audio file for this exercise."
      />
      <EnhancedImageInput
        source="image"
        label="Upload an optional image for this exercise."
      />
      <EnhancedVideoInput
        source="video"
        label="Upload a video for this exercise if you want. Do not upload both an image and a video."
      />
      <ArrayInput
        source="groupOptions"
        defaultValue={[{ id: uuidv4() }]}
        label="Each answer option must have the same number of words as the blanks above"
      >
        <SimpleFormIterator disableReordering>
          <ArrayInput source="answerOptions">
            <SimpleFormIterator addButton={<AddItemWithDefaultIdButton />}>
              <TextInput source="answer" />
              <NumberInput source="count" defaultValue={1} />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              //The reasonning is that during create or edit, there is an undefined; however,
              //during pre-submission processing, all of that gets replaced with an aws link
              console.log(formData);
              //@ts-ignore
              const curSource = getSource("rightAnswers");
              return (
                scopedFormData && (
                  <ArrayInput source={curSource}>
                    <SimpleFormIterator>
                      <SelectInput
                        validate={required(
                          "You have not specified all answers. Please do so."
                        )}
                        source={"answerOptionId"}
                        choices={scopedFormData.answerOptions}
                        optionText={"answer"}
                        optionValue={"id"}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
