import {
  useRedirect,
  useNotify,
  Toolbar,
  SaveButton,
  Button,
} from "react-admin";
import { reorder } from "../../utilities/dataprovider/reorderRequest";

export const ReorderToolbar = ({
  order,
  currentData,
  resource,
  redirectParams = null,
}) => {
  const redirect = useRedirect();
  const notify = useNotify();
  //Overriding the save (no dataprovider actions needed)
  //if curPopupValues exist means we need to update the current popup
  //else insert a new one
  //data is not used (no role for the course or the language in reordering)
  const save = () => {
    for (const [index, element] of order.entries()) {
      currentData[element].order = index + 1;
    }
    return reorder(resource, currentData);
  };
  return (
    <Toolbar>
      <Button
        label={"Reorder " + resource}
        onClick={async (e) => {
          e.preventDefault();
          const resp = await save();
          if (resp.status === 200) {
            notify("Reordered successfully");
            //If there is a redirectParam, it is for an lessonId when rearrnaging exercises
            if (redirectParams !== null) {
              //@ts-ignore
              redirect("edit", "lesson", redirectParams.lessonId);
            } else {
              redirect("list", resource);
            }
          } else notify("Error in reordering", { type: "error" });
        }}
        type="button"
        variant="contained"
      />
    </Toolbar>
  );
};
