import { DEFAULT_FONT_SIZE } from "../../../exercises/defaultValues";
import { TYPE } from "../../../utilities/constants";
import {
  BLANK,
  DICTIONARY,
  DROPDOWN,
  EXAMPLE,
  LISTEN,
  LTR,
  MICROPHONE,
  POPUP,
  RTL,
  SPEAKER,
} from "./constants";

export const serialize = (fullValues) => {
  if (typeof fullValues === "string") {
    return fullValues;
  }

  //N.B. Differentiate between ingroup alignment and overall alignment (dir vs textAlign)
  const serializedValue = fullValues
    .map((row) => {
      let finalText = "";
      //If the line is English (LTR) >> no need for a div as it would automaticlly be rendered like that
      //hence for English, need to wrap Arabic text in RTL spans; however for Arabic lines, >> need a div to set dir
      //and then English text needs to be wrapped in spans with LTR tags
      let dirTargeted = RTL;
      if (
        row.children[0] &&
        row.children[0].dir &&
        row.children[0].dir === RTL
      ) {
        dirTargeted = LTR;
      }
      row.children.forEach((item) => {
        finalText = finalText + serializeElement(item, dirTargeted);
      });

      if (finalText.length === 0) {
        return "";
      }

      let textAlign = row.textAlign ? `textAlign:${row.textAlign}` : "";
      //Wrapping the div in case it is an Arabic line
      if (dirTargeted === LTR) {
        finalText = `<div ${textAlign} dir='${RTL}'>${finalText}</div>`;
      }
      //Only need to add the overall textAlign if it exists
      else {
        finalText = `<div ${textAlign}>${finalText}</div>`;
      }
      return finalText;
    })
    .join("<br>");
  //If there were only multiple empty rows >> need to check for a series of line breaks
  if (/^(<br>).*$/.test(serializedValue)) {
    return "";
  }
  return serializedValue;
};

const serializeElement = (element, dirTargeted) => {
  if (!element.hasOwnProperty(TYPE)) {
    if (element.text.trim().length === 0) {
      return element.text.trim();
    }
    let props = "";
    if (element.bold) {
      props = props + "fontWeight:bold;";
    }
    if (element.underline) {
      props = props + "textDecoration:underline;";
    }
    if (element.italic) {
      props = props + "fontStyle:italic;";
    }
    if (element.color) {
      props = props + "color:" + element.color + ";";
    }
    if (element.fontSize && element.fontSize !== DEFAULT_FONT_SIZE) {
      props = props + "fontSize:" + element.fontSize + ";";
    }
    props = props.length !== 0 ? "style='" + props + "'" : "";
    const dir = element?.dir === dirTargeted ? `dir='${dirTargeted}' ` : "";

    if (props.length === 0 && dir.length === 0) {
      return element.text;
    }
    return `<span ${dir}${props}>${element.text}</span>`;
  } else {
    switch (element.type) {
      case MICROPHONE:
        return " $[microphone]";
      case BLANK:
        return " $[blank]";
      case DROPDOWN:
        return "$[dropdown]";
      case SPEAKER:
        return `$[speaker/${element.speakerText}]`;
      case LISTEN:
        return `$[listen/${element.listenText}]`;
      case DICTIONARY:
        return `$[dictionary/${element.dictionaryText}]`;

      case EXAMPLE:
        return `$[examples_pop_up/${element.word}/${element.examples
          .map((it) => it.text)
          .join("|")}]`;
      case POPUP:
        if (element.afterQuestion) {
          return `$[quotes_pop_up/${element.popupid}/after_question]`;
        } else {
          return `$[quotes_pop_up/${element.popupid}]`;
        }
      default:
        return;
    }
  }
};
