import {
  BooleanInput,
  SelectInput,
  ReferenceInput,
  Create,
  SimpleForm,
} from "react-admin";
import { ContentEditor } from "../customInputs/contentEditor/ContentEditor";
import { ConditionalLessonLimitInput } from "./components/ConditionalLessonLimitInput";
import { ConditionalVideoInput } from "./components/ConditionalVideoInput";
import { CourseLessonsInput } from "./components/CourseLessonsInput";
import { TranslatesDescription } from "../customInputs";
import { LessonDurationNumberInput } from "./components/LessonDurationNumberInput";
import { lessonTransformer } from "./lessonFormTools";

export const LessonCreate = () => {
  return (
    <Create transform={lessonTransformer}>
      <SimpleForm warnWhenUnsavedChanges>
        <ReferenceInput source="lessonTypeId" reference="lessonTypes">
          <SelectInput
            optionText="type"
            optionValue="id"
            helperText="Select the lesson type"
          />
        </ReferenceInput>
        <LessonDurationNumberInput />
        <BooleanInput
          source="isPublic"
          label="Public Access"
          helperText="Should users be able to access this lesson without signing up?"
        />
        <ContentEditor source="seoDescription" label="SEO Description" />
        <CourseLessonsInput />
        <ConditionalVideoInput />
        <ConditionalLessonLimitInput />
        <TranslatesDescription
          listOfInputs={["title", "subtitle", "description"]}
        />
      </SimpleForm>
    </Create>
  );
};
