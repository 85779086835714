import EditorButton from "./Button";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRightRounded";

export const AlignRightButton = ({ onMouseDown, border }) => {
  return (
    <EditorButton onMouseDown={onMouseDown} border={border}>
      <FormatAlignRightIcon />
    </EditorButton>
  );
};
