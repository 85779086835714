import EditorButton from "./Button";
import ItalicIcon from "@mui/icons-material/FormatItalicRounded";

export const ItalicButton = ({ onMouseDown, border }) => {
  return (
    <EditorButton onMouseDown={onMouseDown} border={border}>
      <ItalicIcon />
    </EditorButton>
  );
};
