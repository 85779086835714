/**
 *
 * @param fullLessonList all lessons in the database
 * @param courseId the current courseId
 * @returns
 */

export const getNextOrderValue = (fullLessonList, courseId) => {
  //Go through each lesson and through each courseLesson (there can be multiple)
  const curLessonList = fullLessonList?.filter((item) => {
    return item.courseLessons
      ? item.courseLessons
          .map((itemCourseLesson) => itemCourseLesson.courseId === courseId)
          .some((item) => item)
      : false;
  });
  //Get the order of the latest lesson, add 1 to it
  const curCourseLessons = curLessonList
    ?.map(
      (item) =>
        item.courseLessons.filter((item) => item.courseId === courseId)[0].order
    )
    .sort((a, b) => a - b);
  if (curCourseLessons.length === 0) {
    return 1;
  } else {
    //@ts-ignore
    return curCourseLessons[curCourseLessons.length - 1] + 1;
  }
};
