import { Button } from "@mui/material";

const EditorButton = (props) => {
  return (
    <Button
      variant="outlined"
      sx={{
        boxShadow: "none",
        textTransform: "none",
        fontSize: 16,
        padding: "6px 12px",
        lineHeight: 1.5,
        marginRight: "12px",
        marginLeft: "12px",
        border: props.border ? "1px solid" : "0px",
      }}
      onMouseDown={props.onMouseDown}
    >
      {props.children}
    </Button>
  );
};

export default EditorButton;
