import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  SaveContextProvider,
  SimpleForm,
  useCreate,
  useRefresh,
} from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import { MiniContentEditor } from "../../MiniContentEditor";
import { serialize } from "../../utilities/serialization";

export const CreatePopupDialog = ({
  createDialogState,
  setCreateDialogState,
}) => {
  //Defining the create function
  const [create] = useCreate();
  const refresh = useRefresh();

  // Defining the values for the savecontext
  const save = async (popupData) => {
    for (const [key, value] of Object.entries(popupData)) {
      popupData[key] = serialize(value);
    }
    await create(
      "popups",
      { data: popupData },
      {
        onSuccess: (data) => {
          refresh();
          handleCreateClose();
        },
      }
    );
  };
  const saving = false;
  const mutationMode = "pessimistic";

  const handleCreateClose = () => {
    setCreateDialogState(false);
  };
  return (
    <Dialog
      anchor="right"
      id="create-drawer"
      open={createDialogState}
      onClose={handleCreateClose}
      fullScreen
    >
      <DialogTitle disableTypography className={"create-dialog-title"}>
        <h2>Popup create form...</h2>
        <IconButton onClick={handleCreateClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SaveContextProvider value={{ save, saving, mutationMode }}>
          <SimpleForm>
            <MiniContentEditor source="ar" label="Arabic popup text" />
            <MiniContentEditor source="en" label="English popup text" />
            <MiniContentEditor source="fr" label="French popup text" />
          </SimpleForm>
        </SaveContextProvider>
      </DialogContent>
    </Dialog>
  );
};
