import { AuthProvider } from "react-admin";
import { generateFromString } from "generate-avatar";

export const authProvider: AuthProvider = {
  login: ({ username, password }: any) => {
    const request = new Request(
      process.env.REACT_APP_BACKEND + "/panelUsers/auth/login",
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  logout: (params: any) => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  checkAuth: (params: any) => {
    if (localStorage.getItem("auth")) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  getIdentity: () => {
    try {
      const { id, username } = JSON.parse(
        localStorage.getItem("auth") as string
      );
      const avatar = generateFromString(id);
      return Promise.resolve({ id, username, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: (params: any) => Promise.resolve(),
};
