import {
  SimpleForm,
  Edit,
  TextInput,
  NumberInput,
  NumberField,
} from "react-admin";

export const PanelOrganizationEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <NumberInput source="maxUsers" />
        <NumberField source="currentUsers" />
      </SimpleForm>
    </Edit>
  );
};
