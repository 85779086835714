import { useState } from "react";
import { Edit, useNotify, useRedirect, useRefresh } from "react-admin";
import { ExerciseEditActions } from "./ExerciseEditActions";
import { ExerciseFormSkeleton } from "./ExerciseFormSkeleton";
import { formTransformer } from "./exerciseFormTools";
import { MainExerciseStructure } from "./MainExerciseStructure";

export const ExerciseEdit = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (data) => {
    notify(`Changes saved`);
    data?.lessonExercises
      ? redirect("edit", "lessons", data.lessonExercises[0].lessonId)
      : redirect("show");
    refresh();
  };

  const [createNoteDialogState, setCreateNoteDialogState] =
    useState<boolean>(false);

  const handleDialogClose = () => {
    setCreateNoteDialogState(false);
  };

  return (
    <Edit
      transform={formTransformer}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
      actions={
        <ExerciseEditActions
          setCreateNoteDialogState={setCreateNoteDialogState}
        />
      }
    >
      <ExerciseFormSkeleton
        MainExerciseStructure={MainExerciseStructure}
        createNoteDialogState={createNoteDialogState}
        handleDialogClose={handleDialogClose}
      />
    </Edit>
  );
};
