export const reorder = async (resource, params) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  console.log(params);
  console.log(resource);
  const resp = await fetch(
    `${process.env.REACT_APP_BACKEND}/${resource}/reorder`,
    {
      method: "POST",
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(params), // body data type must match "Content-Type" header
    }
  );
  console.log("Reorder resp");
  console.log(resp);
  return resp;
};
