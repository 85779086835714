import { Editor } from "slate";
import { fixOverallSpaces } from "../utilities/spacing";

export const withEnter = (editor: Editor) => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    fixOverallSpaces(editor);
    return insertBreak();
  };

  return editor;
};
