import { Box, Card, CardContent } from "@mui/material";

import { ExercisesFilters } from "./ExercisesFilters";
export const ExercisesFilterAside = () => (
  <Box
    sx={{
      display: {
        sm: "block",
      },
      order: -1, // display on the left rather than on the right of the list
      width: "20em",
      overflow: "visible",
      marginTop: "4em",
      height: "100%",
      marginRight: "1em",
    }}
  >
    <Card>
      <CardContent>
        <ExercisesFilters />
      </CardContent>
    </Card>
  </Box>
);
