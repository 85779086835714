export const BOLD = "bold";
export const ITALIC = "italic";
export const UNDERLINE = "underline";
export const BLANK = "blank";
export const SPEAKER = "speaker";
export const MICROPHONE = "microphone";
export const DICTIONARY = "dictionary";
export const DROPDOWN = "dropdown";
export const EXAMPLE = "example";
export const POPUP = "popup";
export const LISTEN = "listen";
export const COLOR = "color";
export const FONTSIZE = "fontSize";

export const LEFT = "left";
export const RIGHT = "right";
export const CENTER = "center";

//Alignment and direction constants
export const LTR = "ltr";
export const RTL = "rtl";
export const NON_ALPHABET = "non alphabet";
export const DIR = "dir";

export const presetColors = [
  "#FBEDC4",
  "#9494C1",
  "#F8A737",
  "#1A4461",
  "#695186",
  "#EC9222",
  "#D0021B",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#584873",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
  "#FFFFFF",
];
