import EditorButton from "./Button";
import UnderlineIcon from "@mui/icons-material/FormatUnderlined";

export const UnderlineButton = ({ onMouseDown, border }) => {
  return (
    <EditorButton onMouseDown={onMouseDown} border={border}>
      <UnderlineIcon />
    </EditorButton>
  );
};
