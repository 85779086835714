import { Button, TopToolbar, ShowButton } from "react-admin";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

export const ExerciseEditActions = ({ setCreateNoteDialogState }) => (
  <TopToolbar>
    <ShowButton />
    <Button
      onClick={() => {
        setCreateNoteDialogState(true);
      }}
      label="Create note"
    >
      <NoteAddIcon />
    </Button>
  </TopToolbar>
);
