import { Book } from "@mui/icons-material";
import { FilterList, FilterListItem, useGetList } from "react-admin";
import { NotePriority, NoteStatus } from "../../utilities/enums";
import { Box, Card, CardContent } from "@mui/material";
import { sentenceCase } from "sentence-case";

const ResourceNoteAsideFilters = () => {
  const { data: panelUsersList } = useGetList("panelUsers", {
    pagination: { perPage: 100, page: 1 },
  });

  return (
    <>
      {panelUsersList ? (
        <FilterList label="Assigned to" icon={<Book />}>
          {panelUsersList.map((item) => (
            <FilterListItem
              label={sentenceCase(item.username)}
              key={item.id}
              value={{ "assignedToPanelUserId||$eq||": item.id }}
            />
          ))}
        </FilterList>
      ) : (
        <></>
      )}

      <FilterList label="Priority" icon={<Book />}>
        {Object.values(NotePriority).map((item) => (
          <FilterListItem
            label={sentenceCase(item)}
            key={item}
            value={{ "priority||$eq||": item }}
          />
        ))}
      </FilterList>
      <FilterList label="Status" icon={<Book />}>
        {Object.values(NoteStatus).map((item) => (
          <FilterListItem
            label={sentenceCase(item)}
            key={item}
            value={{ "status||$eq||": item }}
          />
        ))}
      </FilterList>
      <FilterList label="Resource" icon={<Book />}>
        {["COURSE", "LESSON", "EXERCISE"].map((item) => (
          <FilterListItem
            label={sentenceCase(item)}
            key={item}
            value={{ "resource||$eq||": item }}
          />
        ))}
      </FilterList>
    </>
  );
};

export const ResourceNoteListAside = () => (
  <Box
    sx={{
      display: {
        sm: "block",
      },
      order: -1, // display on the left rather than on the right of the list
      width: "20em",
      overflow: "visible",
      marginTop: "4em",
      height: "100%",
      marginRight: "1em",
    }}
  >
    <Card>
      <CardContent>
        <ResourceNoteAsideFilters />
      </CardContent>
    </Card>
  </Box>
);
