export enum NotePriority {
  NICE_TO_ADD = "NICE TO ADD",
  LOW = "LOW",
  NORMAL = "NORMAL",
  HIGH = "HIGH",
  URGENT = "URGENT",
}

export enum NoteStatus {
  OPEN = "OPEN",
  IN_PROGRESS = "IN PROGRESS",
  RESOLVED = "RESOLVED",
}
