import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { CustomEditor } from "../../utilities/CustomEditor";
import { useEffect } from "react";

export const SpeakerDialogForm = ({
  editor,
  speakerDialogState,
  setSpeakerDialogState,
  curSpeakerValues,
  setCurSpeakerValues,
}) => {
  //Defining the handleClose function for the dialog
  //It should clear any example values and reset the form
  const handleClose = () => {
    setCurSpeakerValues(null);
    setSpeakerDialogState(false);
    reset();
  };

  //Define default values for an empty example value
  let defaultValues = {
    speakerText: "",
  };

  //Generate the form
  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultValues,
  });

  //if values are given, need to update the form with those values
  useEffect(() => {
    if (curSpeakerValues) {
      setValue("speakerText", curSpeakerValues.speakerText);
    }
  }, [curSpeakerValues, setValue]);

  //Handling form submission (whether insertion or editing)
  const submitDialog = () => {
    //Need to update the currentword as opposed to inserting
    if (curSpeakerValues) {
      CustomEditor.updateSpeaker(editor, getValues("speakerText"));
    } else {
      CustomEditor.insertSpeaker(editor, getValues("speakerText"));
    }
    handleClose();
    reset();
  };
  return (
    <Dialog open={speakerDialogState} onClose={handleClose}>
      <DialogTitle>Speaker Dialog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the statement which you would like to be read. Please
          note that only a speaker will be rendered without the inputted text.
        </DialogContentText>
        <form>
          <Controller
            name="speakerText"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Type the statement"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" onClick={submitDialog}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
