import Picker from "emoji-picker-react";
import React, { useState } from "react";
import reactCSS from "reactcss";

export const EmojiInput = ({ handleChange }) => {
  const [displayState, setDisplayState] = useState(false);

  const handleClick = () => {
    setDisplayState(!displayState);
  };

  const handleClose = () => {
    setDisplayState(false);
  };

  //The styles are similar to the ones used for the colorpicker
  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "25px",
        borderRadius: "2px",
        background: "#fff",
        textAlign: "center",
        fontSize: "large",
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 0.8px rgba(25,118,210)",
        display: "inline-block",
        borderColor: "#1976d2",
        cursor: "pointer",
        marginRight: "7px",
        marginLeft: "7px",
        marginTop: "5px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color}>😀</div>
      </div>
      {displayState ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <Picker onEmojiClick={handleChange} disableSkinTonePicker />
        </div>
      ) : null}
    </div>
  );
};
