import { CardContent, Rating } from "@mui/material";
import {
  TextField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  Datagrid,
  List,
  RichTextField,
  useRecordContext,
  ReferenceField,
} from "react-admin";
import { LessonsFilterSidebar } from "./components/LessonFilterAside";
import get from "lodash/get";

export const LessonList = (props: any) => {
  const RatingField = ({ source }) => {
    const record = useRecordContext(props);
    if (record) {
      const curRanking = get(record, source);
      if (curRanking) {
        return (
          <Rating
            name="read-only"
            value={get(record, source)}
            precision={0.1}
            readOnly
          />
        );
      } else {
        return null;
      }
    }
    return null;
  };

  const LessonPanel = () => {
    return (
      <CardContent style={{ paddingLeft: "100px", paddingRight: "100px" }}>
        <RichTextField align="right" fullWidth source="seoDescription" />
      </CardContent>
    );
  };

  return (
    <>
      <List
        {...props}
        aside={<LessonsFilterSidebar />}
        sort={{ field: "courseLessons.order", order: "DESC" }}
      >
        <Datagrid rowClick="edit" expand={<LessonPanel />}>
          <ReferenceField
            label="Lesson Type"
            source="lessonTypeId"
            reference="lessonTypes"
          >
            <TextField source="type" />
          </ReferenceField>
          <ArrayField
            source="courseLessons"
            sortBy="courseLessons.order"
            label="Lesson Order"
          >
            <SingleFieldList linkType="false">
              <TextField source="order" />
            </SingleFieldList>
          </ArrayField>
          {/* <ArrayField
            source="courseLessons"
            // sortBy="courseLessons.rating"
            sortable={false}
            label="Lesson Rating"
          >
            <SingleFieldList linkType="false">
              <RatingField source="rating" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField
            source="courseLessons"
            sortable={false}
            label="Average Time"
          >
            <SingleFieldList linkType="false">
              <NumberField
                label="In minutes"
                source="averageTime"
                options={{ style: "unit", unit: "minute" }}
              />
            </SingleFieldList>
          </ArrayField>
          <ArrayField
            source="courseLessons"
            sortable={false}
            label="Solve Rate"
          >
            <SingleFieldList linkType="false">
              <NumberField
                label="In minutes"
                source="solveRate"
                options={{ style: "percent" }}
              />
            </SingleFieldList>
          </ArrayField> */}
          <ArrayField
            source="courseLessons"
            label="Course"
            sortBy="courseLessons.course.level"
          >
            <SingleFieldList linkType="false">
              <TextField source="course.level" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="translates" sortable={false}>
            <Datagrid
              isRowSelectable={(record) => {
                return false;
              }}
            >
              <TextField source="title" />
              <TextField source="subtitle" />
              <TextField source="description" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="isPublic" />
        </Datagrid>
      </List>
    </>
  );
};
