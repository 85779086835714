import {
  ArrayInput,
  FormDataConsumer,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  SelectInput,
  required,
} from "react-admin";
import { AudioInput } from "../../customInputs/AudioInput";
import { ContentEditor } from "../../customInputs/contentEditor/ContentEditor";
import { EnhancedImageInput } from "../../customInputs/ImageInput";
import { EnhancedVideoInput } from "../../customInputs/VideoInput";
import { AddItemWithDefaultIdButton } from "./AddItemWithDefaultIdButton";

export const DragAndDropExercise = () => {
  return (
    <>
      <ContentEditor
        source="content"
        label="The text to be shown to the user"
      />
      <TextInput fullWidth source="description" label="Text below image" />
      <NumberInput
        source="maxTryCount"
        label="What is the maximum try count?"
        defaultValue={2}
      />
      <ContentEditor
        source="question"
        label="The questions the user has to fill"
      />
      <AudioInput
        source="audio"
        label="Upload an audio file for this exercise."
      />
      <EnhancedImageInput
        source="image"
        label="Upload an optional image for this exercise."
      />
      <EnhancedVideoInput
        source="video"
        label="Upload a video for this exercise. Do not upload both image and video."
      />
      <ArrayInput source="groupOptions" defaultValue={[{}]}>
        <SimpleFormIterator disableReordering disableClear disableAdd>
          <ArrayInput source="answerOptions">
            <SimpleFormIterator addButton={<AddItemWithDefaultIdButton />}>
              <TextInput source="answer" />
              <NumberInput source="count" defaultValue={1} />
            </SimpleFormIterator>
          </ArrayInput>

          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              return (
                <ArrayInput
                  source={getSource ? getSource("rightAnswers") : "null"}
                >
                  <SimpleFormIterator>
                    <SelectInput
                      validate={required(
                        "You have not specified all answers. Please do so."
                      )}
                      source={`answerOptionId`}
                      choices={scopedFormData.answerOptions}
                      optionText={"answer"}
                      optionValue={"id"}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
